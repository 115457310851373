





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  TaxesQuery
} from '@/generated/graphql'
import { sdk } from '@/graphql/client'
import { format, parse } from 'date-fns'
@Component({ components: {} })
export default class Admins extends Vue {
  @Prop() month!: string

  taxes: TaxesQuery['taxes'] = [];
  fields = ['month',
    { key: 'giftcards', label: 'Gift Cards' },
    { key: 'roomAmount', label: 'Rooms' },
    { key: 'extraWithGratuity', label: 'Extra (Tipped)' },
    { key: 'extraWithoutGratuity', label: 'Extra (Not Tipped)' },
    { key: 'gratuity', label: 'Tips' },
    { key: 'taxCollected', label: 'TaxCollected' },
    { key: 'refundedAmount', label: 'Refunds' },
    { key: 'discounts', label: 'Discounts' },
    { key: 'gross', label: 'Gross' },
    { key: 'total', label: 'Total' }
  ];

  loading = false;
  mounted () {
    this.loadTaxes()
  }

  calculatedGross (taxRow): number {
    return taxRow.giftcards +
      taxRow.roomAmount +
      taxRow.extraWithGratuity +
      taxRow.extraWithoutGratuity +
      taxRow.extraWithGratuity * 0.18 +
      taxRow.capturedTax -
      taxRow.discounts
  }

  gross (taxRow): number {
    return taxRow?.giftcards + taxRow.capturedAmount
  }

  total (taxRow): number {
    return this.calculatedGross(taxRow) - taxRow.refundedAmount
  }

  dateFormat (string) {
    if (this.month) {
      return format(parse(string, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy')
    } else {
      return format(parse(string, 'yyyy-MM-dd', new Date()), 'MMM yyyy')
    }
  }

  async loadTaxes () {
    this.loading = true
    const taxes = await sdk.Taxes({ month: this.month })
    this.taxes = taxes.taxes
    this.loading = false
  }

  @Watch('month')
  onMonthChange () {
    this.loadTaxes()
  }
}
