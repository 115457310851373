















import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { Maybe, SettingKey, SettingQuery } from '@/generated/graphql'

@Component({ components: {} })
export default class Form extends Vue {
  process = false

  @Prop() setting: Maybe<SettingQuery['setting']>;
  @Prop() saving;

  get processing () {
    return this.saving
  }

  get options () {
    return [
      { value: SettingKey.CtaText, text: 'Promotion Banner' }
    ]
  }

  form = {
    key: SettingKey.CtaText,
    value: ''
  } as { key: number | string, value: string};

  validations () {
    return {
      form: {
        key: { required },
        value: { required }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.setting) {
      this.form.key = this.setting.key
      this.form.value = this.setting.value
    }
  }

  async submit () {
    this.process = true
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return
    this.$emit('save', this.form)
    this.process = false
  }
}
