import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  ISODate: any;
  /** Dattime custom scalar type */
  ISODateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Admin = {
  __typename?: 'Admin';
  email: Scalars['String'];
  id: Scalars['Int'];
  pin?: Maybe<Scalars['Int']>;
  role: Role;
};

export type AdminCreateInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  pin?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Role>;
};

export type AdminUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CartInput = {
  date: Scalars['ISODate'];
  extras: Array<InputMaybe<CartExtraInput>>;
  notes?: InputMaybe<Scalars['String']>;
  redeemCode?: InputMaybe<Scalars['String']>;
  timeSlots: Array<Scalars['Int']>;
};

export type Client = {
  __typename?: 'Client';
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ClientInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  extras?: Maybe<Array<Maybe<DashboardAggregate>>>;
  reservations?: Maybe<Array<Maybe<DashboardAggregate>>>;
  rooms?: Maybe<Array<Maybe<DashboardAggregate>>>;
};

export type DashboardAggregate = {
  __typename?: 'DashboardAggregate';
  name: Scalars['String'];
  value: Scalars['Float'];
};

export enum Direction {
  Down = 'DOWN',
  Up = 'UP'
}

export type Extra = {
  __typename?: 'Extra';
  description: Scalars['String'];
  id: Scalars['Int'];
  image?: Maybe<ExtraImage>;
  includeInGratuity: Scalars['Boolean'];
  position: Scalars['Int'];
  price: Scalars['Int'];
  title: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type ExtraImage = {
  __typename?: 'ExtraImage';
  extra: Extra;
  id: Scalars['Int'];
  path: Scalars['String'];
  variants?: Maybe<ImageVariant>;
};

export type ExtraImageInput = {
  path: Scalars['String'];
  variants?: InputMaybe<ImageVariantInput>;
};

export type ExtraInput = {
  description: Scalars['String'];
  image: ExtraImageInput;
  includeInGratuity: Scalars['Boolean'];
  price: Scalars['Int'];
  title: Scalars['String'];
  value?: InputMaybe<Scalars['Int']>;
};

export type Giftcard = {
  __typename?: 'Giftcard';
  amount: Scalars['Float'];
  code: Scalars['String'];
  createdAt: Scalars['ISODateTime'];
  id: Scalars['Int'];
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  recipient: Scalars['String'];
  reservations?: Maybe<Array<Reservation>>;
  senderName: Scalars['String'];
  status: GiftcardStatus;
};

export type GiftcardAdminInput = {
  amount: Scalars['Int'];
  code: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  recipient: Scalars['String'];
  senderName: Scalars['String'];
};

export type GiftcardBalance = {
  __typename?: 'GiftcardBalance';
  amount: Scalars['Float'];
  left: Scalars['Float'];
  status: GiftcardStatus;
};

export type GiftcardInput = {
  amount: Scalars['Int'];
  message?: InputMaybe<Scalars['String']>;
  recipient: Scalars['String'];
  senderName: Scalars['String'];
};

export enum GiftcardStatus {
  Paid = 'PAID',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

export type ImageVariant = {
  __typename?: 'ImageVariant';
  large?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  small?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
};

export type ImageVariantInput = {
  large?: InputMaybe<Scalars['String']>;
  original?: InputMaybe<Scalars['String']>;
  small?: InputMaybe<Scalars['String']>;
  thumb?: InputMaybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminCreate?: Maybe<Admin>;
  adminDelete?: Maybe<Scalars['Boolean']>;
  adminUpdate?: Maybe<Admin>;
  cartCheckout?: Maybe<Scalars['String']>;
  extraCreate?: Maybe<Extra>;
  extraDelete: Scalars['Boolean'];
  extraMove?: Maybe<Extra>;
  extraUpdate: Extra;
  giftcardCheckout?: Maybe<Scalars['String']>;
  giftcardCreate?: Maybe<Giftcard>;
  giftcardResend?: Maybe<Giftcard>;
  giftcardUpdate?: Maybe<Giftcard>;
  login?: Maybe<Scalars['String']>;
  promotionCreate?: Maybe<Promotion>;
  promotionDelete?: Maybe<Scalars['Boolean']>;
  promotionUpdate?: Maybe<Promotion>;
  reservationCreate: Reservation;
  reservationRebook: Reservation;
  reservationRefund: Scalars['Boolean'];
  roomCreate?: Maybe<Room>;
  roomDelete?: Maybe<Scalars['Boolean']>;
  roomMove?: Maybe<Room>;
  roomUpdate?: Maybe<Room>;
  setAdminNote: Reservation;
  setWaiter: Reservation;
  settingCreate?: Maybe<Setting>;
  settingDelete?: Maybe<Scalars['Boolean']>;
  settingUpdate?: Maybe<Setting>;
  slotCreate: Array<Maybe<TimeSlot>>;
  userReservationCancel: Refund;
  waiterCreate?: Maybe<Waiter>;
  waiterDelete: Scalars['Boolean'];
  waiterUpdate: Waiter;
};


export type MutationAdminCreateArgs = {
  input: AdminCreateInput;
};


export type MutationAdminDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationAdminUpdateArgs = {
  id: Scalars['Int'];
  input: AdminCreateInput;
};


export type MutationCartCheckoutArgs = {
  input?: InputMaybe<CartInput>;
};


export type MutationExtraCreateArgs = {
  input: ExtraInput;
};


export type MutationExtraDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationExtraMoveArgs = {
  id: Scalars['Int'];
  position: Scalars['Int'];
};


export type MutationExtraUpdateArgs = {
  id: Scalars['Int'];
  input: ExtraInput;
};


export type MutationGiftcardCheckoutArgs = {
  input: GiftcardInput;
};


export type MutationGiftcardCreateArgs = {
  input: GiftcardAdminInput;
  pin?: InputMaybe<Scalars['Int']>;
};


export type MutationGiftcardResendArgs = {
  id: Scalars['Int'];
};


export type MutationGiftcardUpdateArgs = {
  id: Scalars['Int'];
  input: GiftcardAdminInput;
  pin?: InputMaybe<Scalars['Int']>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPromotionCreateArgs = {
  input: PromotionInput;
};


export type MutationPromotionDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationPromotionUpdateArgs = {
  id: Scalars['Int'];
  input: PromotionInput;
};


export type MutationReservationCreateArgs = {
  input?: InputMaybe<ReservationInput>;
};


export type MutationReservationRebookArgs = {
  adminNotes?: InputMaybe<Scalars['String']>;
  date: Scalars['ISODateTime'];
  id: Scalars['Int'];
  pin?: InputMaybe<Scalars['Int']>;
  timeSlots: Array<ReservationTimeSlotInput>;
};


export type MutationReservationRefundArgs = {
  amount: Scalars['Int'];
  cancel: Scalars['Boolean'];
  id: Scalars['Int'];
  pin?: InputMaybe<Scalars['Int']>;
};


export type MutationRoomCreateArgs = {
  input: RoomCreateInput;
};


export type MutationRoomDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRoomMoveArgs = {
  id: Scalars['Int'];
  position: Scalars['Int'];
};


export type MutationRoomUpdateArgs = {
  id: Scalars['Int'];
  input: RoomCreateInput;
};


export type MutationSetAdminNoteArgs = {
  id: Scalars['Int'];
  note: Scalars['String'];
};


export type MutationSetWaiterArgs = {
  id?: InputMaybe<Scalars['Int']>;
  reservation: Scalars['Int'];
};


export type MutationSettingCreateArgs = {
  input: SettingInput;
};


export type MutationSettingDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationSettingUpdateArgs = {
  input: SettingInput;
};


export type MutationSlotCreateArgs = {
  room: Scalars['Int'];
  slots: Array<InputMaybe<TimeSlotInput>>;
};


export type MutationUserReservationCancelArgs = {
  reference: Scalars['String'];
};


export type MutationWaiterCreateArgs = {
  input: WaiterInput;
};


export type MutationWaiterDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationWaiterUpdateArgs = {
  id: Scalars['Int'];
  input: WaiterInput;
};

export type OwnReservation = {
  __typename?: 'OwnReservation';
  date: Scalars['String'];
  orderTotal: Scalars['Float'];
  reservationTimeSlots: Array<ReservationTimeSlot>;
  status: ReservationStatus;
  stripeReference: Scalars['String'];
};

export enum Period {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type Promotion = {
  __typename?: 'Promotion';
  dayOfWeek: Scalars['Int'];
  id: Scalars['Int'];
  reduction: Scalars['Int'];
};

export type PromotionInput = {
  dayOfWeek: Scalars['Int'];
  reduction: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  admin?: Maybe<Admin>;
  admins: Array<Admin>;
  dashboard: Dashboard;
  extra?: Maybe<Extra>;
  extras: Array<Extra>;
  giftcard?: Maybe<Giftcard>;
  giftcardBalance?: Maybe<GiftcardBalance>;
  giftcards: Array<Giftcard>;
  ownReservation?: Maybe<OwnReservation>;
  promotion?: Maybe<Promotion>;
  promotions: Array<Promotion>;
  reservation?: Maybe<Reservation>;
  reservations: Array<Reservation>;
  room?: Maybe<Room>;
  rooms: Array<Room>;
  setting?: Maybe<Setting>;
  settings: Array<Setting>;
  taxes: Array<Maybe<Tax>>;
  waiter?: Maybe<Waiter>;
  waiters: Array<Waiter>;
};


export type QueryAdminArgs = {
  id: Scalars['Int'];
};


export type QueryDashboardArgs = {
  period?: InputMaybe<Period>;
};


export type QueryExtraArgs = {
  id: Scalars['Int'];
};


export type QueryGiftcardArgs = {
  id: Scalars['Int'];
};


export type QueryGiftcardBalanceArgs = {
  code: Scalars['String'];
};


export type QueryGiftcardsArgs = {
  code?: InputMaybe<Scalars['String']>;
  recipient?: InputMaybe<Scalars['String']>;
  senderName?: InputMaybe<Scalars['String']>;
};


export type QueryOwnReservationArgs = {
  reference: Scalars['String'];
};


export type QueryPromotionArgs = {
  id: Scalars['Int'];
};


export type QueryReservationArgs = {
  id: Scalars['Int'];
};


export type QueryReservationsArgs = {
  date: Scalars['ISODate'];
  statuses?: InputMaybe<Array<ReservationStatus>>;
};


export type QueryRoomArgs = {
  id: Scalars['Int'];
};


export type QueryRoomsArgs = {
  status?: InputMaybe<RoomStatus>;
};


export type QuerySettingArgs = {
  key: SettingKey;
};


export type QueryTaxesArgs = {
  month?: InputMaybe<Scalars['String']>;
};


export type QueryWaiterArgs = {
  id: Scalars['Int'];
};

export type Refund = {
  __typename?: 'Refund';
  amount: Scalars['Int'];
  last4: Scalars['String'];
};

export type Reservation = {
  __typename?: 'Reservation';
  adminNotes?: Maybe<Scalars['String']>;
  client: Client;
  date: Scalars['String'];
  gratuity?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  orderTotal: Scalars['Float'];
  phoneNumber?: Maybe<Scalars['String']>;
  refundableAmount: Scalars['Int'];
  reservationExtras?: Maybe<Array<ReservationExtra>>;
  reservationTimeSlots: Array<ReservationTimeSlot>;
  status: ReservationStatus;
  stripeReference: Scalars['String'];
  waiter?: Maybe<Waiter>;
};

export type ReservationExtra = {
  __typename?: 'ReservationExtra';
  description: Scalars['String'];
  id: Scalars['Int'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  reservation: Reservation;
  title: Scalars['String'];
};

export type ReservationExtraInput = {
  description: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  title: Scalars['String'];
};

export type ReservationInput = {
  client: ClientInput;
  date: Scalars['ISODateTime'];
  notes?: InputMaybe<Scalars['String']>;
  orderTotal: Scalars['Float'];
  reservationExtras?: InputMaybe<Array<ReservationExtraInput>>;
  reservationTimeSlots: Array<ReservationTimeSlotInput>;
  status: ReservationStatus;
  stripeReference: Scalars['String'];
};

export enum ReservationStatus {
  Manual = 'MANUAL',
  Paid = 'PAID',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

export type ReservationTimeSlot = {
  __typename?: 'ReservationTimeSlot';
  date: Scalars['ISODateTime'];
  endTime: Scalars['String'];
  id: Scalars['Int'];
  price: Scalars['Float'];
  reservation: Reservation;
  room: Room;
  startTime: Scalars['String'];
};

export type ReservationTimeSlotInput = {
  date: Scalars['ISODateTime'];
  endTime: Scalars['String'];
  price: Scalars['Float'];
  roomId: Scalars['Int'];
  startTime: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  User = 'USER'
}

export type Room = {
  __typename?: 'Room';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  images: Array<RoomImage>;
  people: Scalars['Int'];
  position: Scalars['Int'];
  status: RoomStatus;
  timeSlots: Array<TimeSlot>;
  title: Scalars['String'];
};

export type RoomCreateInput = {
  description: Scalars['String'];
  images: RoomImageCreateWrapper;
  people: Scalars['Int'];
  status: RoomStatus;
  title: Scalars['String'];
};

export type RoomImage = {
  __typename?: 'RoomImage';
  id: Scalars['Int'];
  order: Scalars['Int'];
  path: Scalars['String'];
  room: Room;
  variants?: Maybe<ImageVariant>;
};

export type RoomImageCreateWrapper = {
  create: Array<RoomImageInput>;
};

export type RoomImageInput = {
  order: Scalars['Int'];
  path: Scalars['String'];
  variants?: InputMaybe<ImageVariantInput>;
};

export enum RoomStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['Int'];
  key: SettingKey;
  value: Scalars['String'];
};

export type SettingInput = {
  key: SettingKey;
  value: Scalars['String'];
};

export enum SettingKey {
  CtaText = 'CTA_TEXT'
}

export type Tax = {
  __typename?: 'Tax';
  capturedAmount?: Maybe<Scalars['Float']>;
  capturedTax?: Maybe<Scalars['Float']>;
  discounts?: Maybe<Scalars['Float']>;
  extraWithGratuity?: Maybe<Scalars['Float']>;
  extraWithoutGratuity?: Maybe<Scalars['Float']>;
  giftcards?: Maybe<Scalars['Float']>;
  month: Scalars['String'];
  refundAmount?: Maybe<Scalars['Float']>;
  refundFees?: Maybe<Scalars['Float']>;
  refundedAmount?: Maybe<Scalars['Float']>;
  roomAmount?: Maybe<Scalars['Float']>;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  id: Scalars['Int'];
  price: Scalars['Int'];
  room: Room;
  startTime: Scalars['String'];
};

export type TimeSlotInput = {
  dayOfWeek: Scalars['Int'];
  endTime: Scalars['String'];
  price: Scalars['Int'];
  startTime: Scalars['String'];
};

export type Waiter = {
  __typename?: 'Waiter';
  firstname: Scalars['String'];
  id: Scalars['Int'];
  lastname: Scalars['String'];
  reservations?: Maybe<Array<Reservation>>;
};

export type WaiterInput = {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
};

export type CartExtraInput = {
  extra: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type DeleteAdminMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAdminMutation = { __typename?: 'Mutation', adminDelete?: boolean | null | undefined };

export type UpdateAdminMutationVariables = Exact<{
  id: Scalars['Int'];
  admin: AdminCreateInput;
}>;


export type UpdateAdminMutation = { __typename?: 'Mutation', adminUpdate?: { __typename?: 'Admin', id: number } | null | undefined };

export type CartCheckoutMutationVariables = Exact<{
  input: CartInput;
}>;


export type CartCheckoutMutation = { __typename?: 'Mutation', cartCheckout?: string | null | undefined };

export type CreateAdminMutationVariables = Exact<{
  admin: AdminCreateInput;
}>;


export type CreateAdminMutation = { __typename?: 'Mutation', adminCreate?: { __typename?: 'Admin', id: number } | null | undefined };

export type CreateExtraMutationVariables = Exact<{
  extra: ExtraInput;
}>;


export type CreateExtraMutation = { __typename?: 'Mutation', extraCreate?: { __typename?: 'Extra', id: number } | null | undefined };

export type CreatePromotionMutationVariables = Exact<{
  promotion: PromotionInput;
}>;


export type CreatePromotionMutation = { __typename?: 'Mutation', promotionCreate?: { __typename?: 'Promotion', id: number } | null | undefined };

export type ReservationCreateMutationVariables = Exact<{
  data: ReservationInput;
}>;


export type ReservationCreateMutation = { __typename?: 'Mutation', reservationCreate: { __typename?: 'Reservation', id: number } };

export type CreateRoomMutationVariables = Exact<{
  room: RoomCreateInput;
}>;


export type CreateRoomMutation = { __typename?: 'Mutation', roomCreate?: { __typename?: 'Room', id: number } | null | undefined };

export type CreateSettingMutationVariables = Exact<{
  setting: SettingInput;
}>;


export type CreateSettingMutation = { __typename?: 'Mutation', settingCreate?: { __typename?: 'Setting', id: number } | null | undefined };

export type CreateWaiterMutationVariables = Exact<{
  waiter: WaiterInput;
}>;


export type CreateWaiterMutation = { __typename?: 'Mutation', waiterCreate?: { __typename?: 'Waiter', id: number } | null | undefined };

export type DeleteExtraMutationVariables = Exact<{
  extra: Scalars['Int'];
}>;


export type DeleteExtraMutation = { __typename?: 'Mutation', extraDelete: boolean };

export type DeletePromotionMutationVariables = Exact<{
  promotion: Scalars['Int'];
}>;


export type DeletePromotionMutation = { __typename?: 'Mutation', promotionDelete?: boolean | null | undefined };

export type DeleteRoomMutationVariables = Exact<{
  room: Scalars['Int'];
}>;


export type DeleteRoomMutation = { __typename?: 'Mutation', roomDelete?: boolean | null | undefined };

export type DeleteSettingMutationVariables = Exact<{
  setting: Scalars['Int'];
}>;


export type DeleteSettingMutation = { __typename?: 'Mutation', settingDelete?: boolean | null | undefined };

export type DeleteWaiterMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteWaiterMutation = { __typename?: 'Mutation', waiterDelete: boolean };

export type EditExtraMutationVariables = Exact<{
  id: Scalars['Int'];
  extra: ExtraInput;
}>;


export type EditExtraMutation = { __typename?: 'Mutation', extraUpdate: { __typename?: 'Extra', id: number } };

export type EditRoomMutationVariables = Exact<{
  id: Scalars['Int'];
  room: RoomCreateInput;
}>;


export type EditRoomMutation = { __typename?: 'Mutation', roomUpdate?: { __typename?: 'Room', id: number } | null | undefined };

export type GiftcardCheckoutMutationVariables = Exact<{
  input: GiftcardInput;
}>;


export type GiftcardCheckoutMutation = { __typename?: 'Mutation', giftcardCheckout?: string | null | undefined };

export type GiftcardCreateMutationVariables = Exact<{
  input: GiftcardAdminInput;
  pin?: InputMaybe<Scalars['Int']>;
}>;


export type GiftcardCreateMutation = { __typename?: 'Mutation', giftcardCreate?: { __typename?: 'Giftcard', id: number } | null | undefined };

export type GiftcardResendMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GiftcardResendMutation = { __typename?: 'Mutation', giftcardResend?: { __typename?: 'Giftcard', id: number, recipient: string } | null | undefined };

export type GiftcardUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  input: GiftcardAdminInput;
  pin?: InputMaybe<Scalars['Int']>;
}>;


export type GiftcardUpdateMutation = { __typename?: 'Mutation', giftcardUpdate?: { __typename?: 'Giftcard', id: number } | null | undefined };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: string | null | undefined };

export type MoveExtraMutationVariables = Exact<{
  id: Scalars['Int'];
  position: Scalars['Int'];
}>;


export type MoveExtraMutation = { __typename?: 'Mutation', extraMove?: { __typename?: 'Extra', id: number } | null | undefined };

export type MoveRoomMutationVariables = Exact<{
  id: Scalars['Int'];
  position: Scalars['Int'];
}>;


export type MoveRoomMutation = { __typename?: 'Mutation', roomMove?: { __typename?: 'Room', id: number } | null | undefined };

export type ReservationRebookMutationVariables = Exact<{
  id: Scalars['Int'];
  date: Scalars['ISODateTime'];
  timeslots: Array<ReservationTimeSlotInput> | ReservationTimeSlotInput;
  adminNotes?: InputMaybe<Scalars['String']>;
}>;


export type ReservationRebookMutation = { __typename?: 'Mutation', reservationRebook: { __typename?: 'Reservation', id: number } };

export type ReservationRefundMutationVariables = Exact<{
  id: Scalars['Int'];
  pin?: InputMaybe<Scalars['Int']>;
  amount: Scalars['Int'];
  cancel: Scalars['Boolean'];
}>;


export type ReservationRefundMutation = { __typename?: 'Mutation', reservationRefund: boolean };

export type SetWaiterMutationVariables = Exact<{
  reservation: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
}>;


export type SetWaiterMutation = { __typename?: 'Mutation', setWaiter: { __typename?: 'Reservation', id: number } };

export type SetAdminNoteMutationVariables = Exact<{
  id: Scalars['Int'];
  note: Scalars['String'];
}>;


export type SetAdminNoteMutation = { __typename?: 'Mutation', setAdminNote: { __typename?: 'Reservation', id: number } };

export type UpdatePromotionMutationVariables = Exact<{
  id: Scalars['Int'];
  promotion: PromotionInput;
}>;


export type UpdatePromotionMutation = { __typename?: 'Mutation', promotionUpdate?: { __typename?: 'Promotion', id: number } | null | undefined };

export type UpdateSettingMutationVariables = Exact<{
  setting: SettingInput;
}>;


export type UpdateSettingMutation = { __typename?: 'Mutation', settingUpdate?: { __typename?: 'Setting', id: number } | null | undefined };

export type UpdateSlotsMutationVariables = Exact<{
  room: Scalars['Int'];
  slots: Array<InputMaybe<TimeSlotInput>> | InputMaybe<TimeSlotInput>;
}>;


export type UpdateSlotsMutation = { __typename?: 'Mutation', slotCreate: Array<{ __typename?: 'TimeSlot', startTime: string, endTime: string, dayOfWeek: number, price: number } | null | undefined> };

export type UserCancelReservationMutationVariables = Exact<{
  reference: Scalars['String'];
}>;


export type UserCancelReservationMutation = { __typename?: 'Mutation', userReservationCancel: { __typename?: 'Refund', last4: string, amount: number } };

export type WaiterUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  input: WaiterInput;
}>;


export type WaiterUpdateMutation = { __typename?: 'Mutation', waiterUpdate: { __typename?: 'Waiter', id: number } };

export type AdminQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AdminQuery = { __typename?: 'Query', admin?: { __typename?: 'Admin', id: number, email: string, role: Role, pin?: number | null | undefined } | null | undefined };

export type AdminsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminsQuery = { __typename?: 'Query', admins: Array<{ __typename?: 'Admin', id: number, email: string, role: Role, pin?: number | null | undefined }> };

export type BookedReservationsQueryVariables = Exact<{
  date: Scalars['ISODate'];
  statuses?: InputMaybe<Array<ReservationStatus> | ReservationStatus>;
}>;


export type BookedReservationsQuery = { __typename?: 'Query', reservations: Array<{ __typename?: 'Reservation', id: number, stripeReference: string, status: ReservationStatus, notes?: string | null | undefined, adminNotes?: string | null | undefined, phoneNumber?: string | null | undefined, gratuity?: number | null | undefined, refundableAmount: number, waiter?: { __typename?: 'Waiter', id: number, firstname: string, lastname: string } | null | undefined, client: { __typename?: 'Client', name: string, email: string }, reservationExtras?: Array<{ __typename?: 'ReservationExtra', title: string, price: number, quantity: number }> | null | undefined, reservationTimeSlots: Array<{ __typename?: 'ReservationTimeSlot', id: number, date: any, startTime: string, endTime: string, price: number, reservation: { __typename?: 'Reservation', id: number, client: { __typename?: 'Client', name: string, email: string } }, room: { __typename?: 'Room', id: number, title: string } }> }> };

export type CheckoutQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckoutQuery = { __typename?: 'Query', extras: Array<{ __typename?: 'Extra', id: number, title: string, price: number, value?: number | null | undefined, description: string, image?: { __typename?: 'ExtraImage', path: string, variants?: { __typename?: 'ImageVariant', original?: string | null | undefined, thumb?: string | null | undefined, small?: string | null | undefined, large?: string | null | undefined } | null | undefined } | null | undefined }>, rooms: Array<{ __typename?: 'Room', id: number, people: number, title: string, status: RoomStatus, images: Array<{ __typename?: 'RoomImage', path: string, variants?: { __typename?: 'ImageVariant', original?: string | null | undefined, thumb?: string | null | undefined, small?: string | null | undefined, large?: string | null | undefined } | null | undefined }> }> };

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = { __typename?: 'Query', dashboard: { __typename?: 'Dashboard', rooms?: Array<{ __typename?: 'DashboardAggregate', name: string, value: number } | null | undefined> | null | undefined, extras?: Array<{ __typename?: 'DashboardAggregate', name: string, value: number } | null | undefined> | null | undefined, reservations?: Array<{ __typename?: 'DashboardAggregate', name: string, value: number } | null | undefined> | null | undefined } };

export type ExtraQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ExtraQuery = { __typename?: 'Query', extra?: { __typename?: 'Extra', title: string, description: string, price: number, value?: number | null | undefined, includeInGratuity: boolean, image?: { __typename?: 'ExtraImage', path: string, variants?: { __typename?: 'ImageVariant', original?: string | null | undefined, thumb?: string | null | undefined, small?: string | null | undefined, large?: string | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type ExtrasQueryVariables = Exact<{ [key: string]: never; }>;


export type ExtrasQuery = { __typename?: 'Query', extras: Array<{ __typename?: 'Extra', id: number, title: string, position: number, price: number, description: string, image?: { __typename?: 'ExtraImage', path: string, variants?: { __typename?: 'ImageVariant', original?: string | null | undefined, thumb?: string | null | undefined, small?: string | null | undefined, large?: string | null | undefined } | null | undefined } | null | undefined }> };

export type GiftcardQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GiftcardQuery = { __typename?: 'Query', giftcardBalance?: { __typename?: 'GiftcardBalance', amount: number, left: number, status: GiftcardStatus } | null | undefined };

export type GiftcardShowQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GiftcardShowQuery = { __typename?: 'Query', giftcard?: { __typename?: 'Giftcard', recipient: string, message?: string | null | undefined, amount: number, left: number, status: GiftcardStatus, code: string, senderName: string } | null | undefined };

export type GiftcardsQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
  recipient?: InputMaybe<Scalars['String']>;
  senderName?: InputMaybe<Scalars['String']>;
}>;


export type GiftcardsQuery = { __typename?: 'Query', giftcards: Array<{ __typename?: 'Giftcard', id: number, recipient: string, message?: string | null | undefined, amount: number, left: number, status: GiftcardStatus, code: string, senderName: string }> };

export type PromotionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PromotionQuery = { __typename?: 'Query', promotion?: { __typename?: 'Promotion', id: number, dayOfWeek: number, reduction: number } | null | undefined };

export type PromotionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PromotionsQuery = { __typename?: 'Query', promotions: Array<{ __typename?: 'Promotion', id: number, dayOfWeek: number, reduction: number }> };

export type ReservationQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type ReservationQuery = { __typename?: 'Query', ownReservation?: { __typename?: 'OwnReservation', date: string, orderTotal: number, status: ReservationStatus, reservationTimeSlots: Array<{ __typename?: 'ReservationTimeSlot', date: any, startTime: string, endTime: string }> } | null | undefined };

export type ReservationShowQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReservationShowQuery = { __typename?: 'Query', reservation?: { __typename?: 'Reservation', id: number, notes?: string | null | undefined, date: string, phoneNumber?: string | null | undefined, client: { __typename?: 'Client', name: string, email: string }, reservationTimeSlots: Array<{ __typename?: 'ReservationTimeSlot', id: number, startTime: string, endTime: string, price: number, room: { __typename?: 'Room', title: string } }> } | null | undefined };

export type ReservationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReservationsQuery = { __typename?: 'Query', rooms: Array<{ __typename?: 'Room', id: number, title: string, timeSlots: Array<{ __typename?: 'TimeSlot', id: number, startTime: string, endTime: string, dayOfWeek: number, price: number, room: { __typename?: 'Room', id: number } }> }> };

export type ReservationsByDateQueryVariables = Exact<{
  date: Scalars['ISODate'];
  statuses?: InputMaybe<Array<ReservationStatus> | ReservationStatus>;
}>;


export type ReservationsByDateQuery = { __typename?: 'Query', reservations: Array<{ __typename?: 'Reservation', id: number, reservationTimeSlots: Array<{ __typename?: 'ReservationTimeSlot', date: any, startTime: string, endTime: string, room: { __typename?: 'Room', id: number } }> }> };

export type RoomQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RoomQuery = { __typename?: 'Query', room?: { __typename?: 'Room', people: number, description?: string | null | undefined, title: string, status: RoomStatus, images: Array<{ __typename?: 'RoomImage', id: number, path: string, order: number }> } | null | undefined };

export type RoomsQueryVariables = Exact<{ [key: string]: never; }>;


export type RoomsQuery = { __typename?: 'Query', rooms: Array<{ __typename?: 'Room', id: number, people: number, title: string, status: RoomStatus, position: number, images: Array<{ __typename?: 'RoomImage', path: string, variants?: { __typename?: 'ImageVariant', original?: string | null | undefined, thumb?: string | null | undefined, small?: string | null | undefined, large?: string | null | undefined } | null | undefined }> }> };

export type RoomsListQueryVariables = Exact<{
  date: Scalars['ISODate'];
  status?: InputMaybe<RoomStatus>;
}>;


export type RoomsListQuery = { __typename?: 'Query', rooms: Array<{ __typename?: 'Room', id: number, people: number, title: string, description?: string | null | undefined, status: RoomStatus, images: Array<{ __typename?: 'RoomImage', path: string, variants?: { __typename?: 'ImageVariant', original?: string | null | undefined, thumb?: string | null | undefined, small?: string | null | undefined, large?: string | null | undefined } | null | undefined }>, timeSlots: Array<{ __typename?: 'TimeSlot', id: number, startTime: string, dayOfWeek: number, price: number, endTime: string, room: { __typename?: 'Room', id: number } }> }>, extras: Array<{ __typename?: 'Extra', id: number, title: string, price: number, description: string, image?: { __typename?: 'ExtraImage', path: string, variants?: { __typename?: 'ImageVariant', original?: string | null | undefined, thumb?: string | null | undefined, small?: string | null | undefined, large?: string | null | undefined } | null | undefined } | null | undefined }>, reservations: Array<{ __typename?: 'Reservation', id: number, reservationTimeSlots: Array<{ __typename?: 'ReservationTimeSlot', date: any, startTime: string, endTime: string, room: { __typename?: 'Room', id: number } }> }>, promotions: Array<{ __typename?: 'Promotion', dayOfWeek: number, reduction: number }>, setting?: { __typename?: 'Setting', value: string } | null | undefined };

export type SettingQueryVariables = Exact<{
  key: SettingKey;
}>;


export type SettingQuery = { __typename?: 'Query', setting?: { __typename?: 'Setting', id: number, key: SettingKey, value: string } | null | undefined };

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'Setting', id: number, key: SettingKey, value: string }> };

export type RoomSlotsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RoomSlotsQuery = { __typename?: 'Query', room?: { __typename?: 'Room', title: string, timeSlots: Array<{ __typename?: 'TimeSlot', id: number, startTime: string, endTime: string, dayOfWeek: number, price: number }> } | null | undefined };

export type TaxesQueryVariables = Exact<{
  month?: InputMaybe<Scalars['String']>;
}>;


export type TaxesQuery = { __typename?: 'Query', taxes: Array<{ __typename?: 'Tax', month: string, giftcards?: number | null | undefined, refundAmount?: number | null | undefined, capturedAmount?: number | null | undefined, capturedTax?: number | null | undefined, roomAmount?: number | null | undefined, extraWithGratuity?: number | null | undefined, extraWithoutGratuity?: number | null | undefined, refundFees?: number | null | undefined, refundedAmount?: number | null | undefined, discounts?: number | null | undefined } | null | undefined> };

export type WaiterQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type WaiterQuery = { __typename?: 'Query', waiter?: { __typename?: 'Waiter', id: number, firstname: string, lastname: string } | null | undefined };

export type WaitersQueryVariables = Exact<{ [key: string]: never; }>;


export type WaitersQuery = { __typename?: 'Query', waiters: Array<{ __typename?: 'Waiter', id: number, firstname: string, lastname: string }> };


export const DeleteAdminDocument = gql`
    mutation DeleteAdmin($id: Int!) {
  adminDelete(id: $id)
}
    `;
export const UpdateAdminDocument = gql`
    mutation UpdateAdmin($id: Int!, $admin: AdminCreateInput!) {
  adminUpdate(id: $id, input: $admin) {
    id
  }
}
    `;
export const CartCheckoutDocument = gql`
    mutation cartCheckout($input: CartInput!) {
  cartCheckout(input: $input)
}
    `;
export const CreateAdminDocument = gql`
    mutation CreateAdmin($admin: AdminCreateInput!) {
  adminCreate(input: $admin) {
    id
  }
}
    `;
export const CreateExtraDocument = gql`
    mutation CreateExtra($extra: ExtraInput!) {
  extraCreate(input: $extra) {
    id
  }
}
    `;
export const CreatePromotionDocument = gql`
    mutation CreatePromotion($promotion: PromotionInput!) {
  promotionCreate(input: $promotion) {
    id
  }
}
    `;
export const ReservationCreateDocument = gql`
    mutation reservationCreate($data: ReservationInput!) {
  reservationCreate(input: $data) {
    id
  }
}
    `;
export const CreateRoomDocument = gql`
    mutation CreateRoom($room: RoomCreateInput!) {
  roomCreate(input: $room) {
    id
  }
}
    `;
export const CreateSettingDocument = gql`
    mutation CreateSetting($setting: SettingInput!) {
  settingCreate(input: $setting) {
    id
  }
}
    `;
export const CreateWaiterDocument = gql`
    mutation CreateWaiter($waiter: WaiterInput!) {
  waiterCreate(input: $waiter) {
    id
  }
}
    `;
export const DeleteExtraDocument = gql`
    mutation DeleteExtra($extra: Int!) {
  extraDelete(id: $extra)
}
    `;
export const DeletePromotionDocument = gql`
    mutation DeletePromotion($promotion: Int!) {
  promotionDelete(id: $promotion)
}
    `;
export const DeleteRoomDocument = gql`
    mutation DeleteRoom($room: Int!) {
  roomDelete(id: $room)
}
    `;
export const DeleteSettingDocument = gql`
    mutation DeleteSetting($setting: Int!) {
  settingDelete(id: $setting)
}
    `;
export const DeleteWaiterDocument = gql`
    mutation DeleteWaiter($id: Int!) {
  waiterDelete(id: $id)
}
    `;
export const EditExtraDocument = gql`
    mutation EditExtra($id: Int!, $extra: ExtraInput!) {
  extraUpdate(id: $id, input: $extra) {
    id
  }
}
    `;
export const EditRoomDocument = gql`
    mutation EditRoom($id: Int!, $room: RoomCreateInput!) {
  roomUpdate(id: $id, input: $room) {
    id
  }
}
    `;
export const GiftcardCheckoutDocument = gql`
    mutation giftcardCheckout($input: GiftcardInput!) {
  giftcardCheckout(input: $input)
}
    `;
export const GiftcardCreateDocument = gql`
    mutation giftcardCreate($input: GiftcardAdminInput!, $pin: Int) {
  giftcardCreate(input: $input, pin: $pin) {
    id
  }
}
    `;
export const GiftcardResendDocument = gql`
    mutation giftcardResend($id: Int!) {
  giftcardResend(id: $id) {
    id
    recipient
  }
}
    `;
export const GiftcardUpdateDocument = gql`
    mutation giftcardUpdate($id: Int!, $input: GiftcardAdminInput!, $pin: Int) {
  giftcardUpdate(id: $id, input: $input, pin: $pin) {
    id
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password})
}
    `;
export const MoveExtraDocument = gql`
    mutation moveExtra($id: Int!, $position: Int!) {
  extraMove(id: $id, position: $position) {
    id
  }
}
    `;
export const MoveRoomDocument = gql`
    mutation moveRoom($id: Int!, $position: Int!) {
  roomMove(id: $id, position: $position) {
    id
  }
}
    `;
export const ReservationRebookDocument = gql`
    mutation ReservationRebook($id: Int!, $date: ISODateTime!, $timeslots: [ReservationTimeSlotInput!]!, $adminNotes: String) {
  reservationRebook(
    id: $id
    date: $date
    timeSlots: $timeslots
    adminNotes: $adminNotes
  ) {
    id
  }
}
    `;
export const ReservationRefundDocument = gql`
    mutation reservationRefund($id: Int!, $pin: Int, $amount: Int!, $cancel: Boolean!) {
  reservationRefund(id: $id, pin: $pin, amount: $amount, cancel: $cancel)
}
    `;
export const SetWaiterDocument = gql`
    mutation setWaiter($reservation: Int!, $id: Int) {
  setWaiter(reservation: $reservation, id: $id) {
    id
  }
}
    `;
export const SetAdminNoteDocument = gql`
    mutation setAdminNote($id: Int!, $note: String!) {
  setAdminNote(id: $id, note: $note) {
    id
  }
}
    `;
export const UpdatePromotionDocument = gql`
    mutation UpdatePromotion($id: Int!, $promotion: PromotionInput!) {
  promotionUpdate(id: $id, input: $promotion) {
    id
  }
}
    `;
export const UpdateSettingDocument = gql`
    mutation UpdateSetting($setting: SettingInput!) {
  settingUpdate(input: $setting) {
    id
  }
}
    `;
export const UpdateSlotsDocument = gql`
    mutation updateSlots($room: Int!, $slots: [TimeSlotInput]!) {
  slotCreate(room: $room, slots: $slots) {
    startTime
    endTime
    dayOfWeek
    price
  }
}
    `;
export const UserCancelReservationDocument = gql`
    mutation userCancelReservation($reference: String!) {
  userReservationCancel(reference: $reference) {
    last4
    amount
  }
}
    `;
export const WaiterUpdateDocument = gql`
    mutation waiterUpdate($id: Int!, $input: WaiterInput!) {
  waiterUpdate(id: $id, input: $input) {
    id
  }
}
    `;
export const AdminDocument = gql`
    query Admin($id: Int!) {
  admin(id: $id) {
    id
    email
    role
    pin
  }
}
    `;
export const AdminsDocument = gql`
    query Admins {
  admins {
    id
    email
    role
    pin
  }
}
    `;
export const BookedReservationsDocument = gql`
    query bookedReservations($date: ISODate!, $statuses: [ReservationStatus!]) {
  reservations(date: $date, statuses: $statuses) {
    id
    stripeReference
    status
    notes
    adminNotes
    phoneNumber
    gratuity
    refundableAmount
    waiter {
      id
      firstname
      lastname
    }
    client {
      name
      email
    }
    reservationExtras {
      title
      price
      quantity
    }
    reservationTimeSlots {
      id
      date
      startTime
      endTime
      price
      reservation {
        id
        client {
          name
          email
        }
      }
      room {
        id
        title
      }
    }
  }
}
    `;
export const CheckoutDocument = gql`
    query Checkout {
  extras {
    id
    title
    image {
      path
      variants {
        original
        thumb
        small
        large
      }
    }
    price
    value
    description
  }
  rooms {
    id
    people
    title
    status
    images {
      path
      variants {
        original
        thumb
        small
        large
      }
    }
  }
}
    `;
export const DashboardDocument = gql`
    query Dashboard {
  dashboard {
    rooms {
      name
      value
    }
    extras {
      name
      value
    }
    reservations {
      name
      value
    }
  }
}
    `;
export const ExtraDocument = gql`
    query Extra($id: Int!) {
  extra(id: $id) {
    title
    description
    price
    value
    includeInGratuity
    image {
      path
      variants {
        original
        thumb
        small
        large
      }
    }
  }
}
    `;
export const ExtrasDocument = gql`
    query Extras {
  extras {
    id
    title
    position
    image {
      path
      variants {
        original
        thumb
        small
        large
      }
    }
    price
    description
  }
}
    `;
export const GiftcardDocument = gql`
    query Giftcard($code: String!) {
  giftcardBalance(code: $code) {
    amount
    left
    status
  }
}
    `;
export const GiftcardShowDocument = gql`
    query GiftcardShow($id: Int!) {
  giftcard(id: $id) {
    recipient
    message
    amount
    left
    status
    code
    senderName
  }
}
    `;
export const GiftcardsDocument = gql`
    query Giftcards($code: String, $recipient: String, $senderName: String) {
  giftcards(code: $code, recipient: $recipient, senderName: $senderName) {
    id
    recipient
    message
    amount
    left
    status
    code
    senderName
  }
}
    `;
export const PromotionDocument = gql`
    query Promotion($id: Int!) {
  promotion(id: $id) {
    id
    dayOfWeek
    reduction
  }
}
    `;
export const PromotionsDocument = gql`
    query Promotions {
  promotions {
    id
    dayOfWeek
    reduction
  }
}
    `;
export const ReservationDocument = gql`
    query Reservation($reference: String!) {
  ownReservation(reference: $reference) {
    date
    orderTotal
    status
    reservationTimeSlots {
      date
      startTime
      endTime
    }
  }
}
    `;
export const ReservationShowDocument = gql`
    query ReservationShow($id: Int!) {
  reservation(id: $id) {
    id
    notes
    date
    phoneNumber
    client {
      name
      email
    }
    reservationTimeSlots {
      id
      startTime
      endTime
      price
      room {
        title
      }
    }
  }
}
    `;
export const ReservationsDocument = gql`
    query Reservations {
  rooms {
    id
    title
    timeSlots {
      id
      startTime
      endTime
      dayOfWeek
      price
      room {
        id
      }
    }
  }
}
    `;
export const ReservationsByDateDocument = gql`
    query ReservationsByDate($date: ISODate!, $statuses: [ReservationStatus!]) {
  reservations(date: $date, statuses: $statuses) {
    id
    reservationTimeSlots {
      date
      startTime
      endTime
      room {
        id
      }
    }
  }
}
    `;
export const RoomDocument = gql`
    query Room($id: Int!) {
  room(id: $id) {
    people
    description
    title
    status
    images {
      id
      path
      order
    }
  }
}
    `;
export const RoomsDocument = gql`
    query Rooms {
  rooms {
    id
    people
    title
    status
    position
    images {
      path
      variants {
        original
        thumb
        small
        large
      }
    }
  }
}
    `;
export const RoomsListDocument = gql`
    query RoomsList($date: ISODate!, $status: RoomStatus) {
  rooms(status: $status) {
    id
    people
    title
    description
    status
    images {
      path
      variants {
        original
        thumb
        small
        large
      }
    }
    timeSlots {
      id
      startTime
      dayOfWeek
      price
      endTime
      room {
        id
      }
    }
  }
  extras {
    id
    title
    image {
      path
      variants {
        original
        thumb
        small
        large
      }
    }
    price
    description
  }
  reservations(date: $date, statuses: [PAID, MANUAL]) {
    id
    reservationTimeSlots {
      date
      startTime
      endTime
      room {
        id
      }
    }
  }
  promotions {
    dayOfWeek
    reduction
  }
  setting(key: CTA_TEXT) {
    value
  }
}
    `;
export const SettingDocument = gql`
    query Setting($key: SettingKey!) {
  setting(key: $key) {
    id
    key
    value
  }
}
    `;
export const SettingsDocument = gql`
    query Settings {
  settings {
    id
    key
    value
  }
}
    `;
export const RoomSlotsDocument = gql`
    query RoomSlots($id: Int!) {
  room(id: $id) {
    title
    timeSlots {
      id
      startTime
      endTime
      dayOfWeek
      price
    }
  }
}
    `;
export const TaxesDocument = gql`
    query Taxes($month: String) {
  taxes(month: $month) {
    month
    giftcards
    refundAmount
    capturedAmount
    capturedTax
    roomAmount
    extraWithGratuity
    extraWithoutGratuity
    refundFees
    refundedAmount
    discounts
  }
}
    `;
export const WaiterDocument = gql`
    query Waiter($id: Int!) {
  waiter(id: $id) {
    id
    firstname
    lastname
  }
}
    `;
export const WaitersDocument = gql`
    query Waiters {
  waiters {
    id
    firstname
    lastname
  }
}
    `;
export type Requester<C= {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    DeleteAdmin(variables: DeleteAdminMutationVariables, options?: C): Promise<DeleteAdminMutation> {
      return requester<DeleteAdminMutation, DeleteAdminMutationVariables>(DeleteAdminDocument, variables, options);
    },
    UpdateAdmin(variables: UpdateAdminMutationVariables, options?: C): Promise<UpdateAdminMutation> {
      return requester<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, variables, options);
    },
    cartCheckout(variables: CartCheckoutMutationVariables, options?: C): Promise<CartCheckoutMutation> {
      return requester<CartCheckoutMutation, CartCheckoutMutationVariables>(CartCheckoutDocument, variables, options);
    },
    CreateAdmin(variables: CreateAdminMutationVariables, options?: C): Promise<CreateAdminMutation> {
      return requester<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, variables, options);
    },
    CreateExtra(variables: CreateExtraMutationVariables, options?: C): Promise<CreateExtraMutation> {
      return requester<CreateExtraMutation, CreateExtraMutationVariables>(CreateExtraDocument, variables, options);
    },
    CreatePromotion(variables: CreatePromotionMutationVariables, options?: C): Promise<CreatePromotionMutation> {
      return requester<CreatePromotionMutation, CreatePromotionMutationVariables>(CreatePromotionDocument, variables, options);
    },
    reservationCreate(variables: ReservationCreateMutationVariables, options?: C): Promise<ReservationCreateMutation> {
      return requester<ReservationCreateMutation, ReservationCreateMutationVariables>(ReservationCreateDocument, variables, options);
    },
    CreateRoom(variables: CreateRoomMutationVariables, options?: C): Promise<CreateRoomMutation> {
      return requester<CreateRoomMutation, CreateRoomMutationVariables>(CreateRoomDocument, variables, options);
    },
    CreateSetting(variables: CreateSettingMutationVariables, options?: C): Promise<CreateSettingMutation> {
      return requester<CreateSettingMutation, CreateSettingMutationVariables>(CreateSettingDocument, variables, options);
    },
    CreateWaiter(variables: CreateWaiterMutationVariables, options?: C): Promise<CreateWaiterMutation> {
      return requester<CreateWaiterMutation, CreateWaiterMutationVariables>(CreateWaiterDocument, variables, options);
    },
    DeleteExtra(variables: DeleteExtraMutationVariables, options?: C): Promise<DeleteExtraMutation> {
      return requester<DeleteExtraMutation, DeleteExtraMutationVariables>(DeleteExtraDocument, variables, options);
    },
    DeletePromotion(variables: DeletePromotionMutationVariables, options?: C): Promise<DeletePromotionMutation> {
      return requester<DeletePromotionMutation, DeletePromotionMutationVariables>(DeletePromotionDocument, variables, options);
    },
    DeleteRoom(variables: DeleteRoomMutationVariables, options?: C): Promise<DeleteRoomMutation> {
      return requester<DeleteRoomMutation, DeleteRoomMutationVariables>(DeleteRoomDocument, variables, options);
    },
    DeleteSetting(variables: DeleteSettingMutationVariables, options?: C): Promise<DeleteSettingMutation> {
      return requester<DeleteSettingMutation, DeleteSettingMutationVariables>(DeleteSettingDocument, variables, options);
    },
    DeleteWaiter(variables: DeleteWaiterMutationVariables, options?: C): Promise<DeleteWaiterMutation> {
      return requester<DeleteWaiterMutation, DeleteWaiterMutationVariables>(DeleteWaiterDocument, variables, options);
    },
    EditExtra(variables: EditExtraMutationVariables, options?: C): Promise<EditExtraMutation> {
      return requester<EditExtraMutation, EditExtraMutationVariables>(EditExtraDocument, variables, options);
    },
    EditRoom(variables: EditRoomMutationVariables, options?: C): Promise<EditRoomMutation> {
      return requester<EditRoomMutation, EditRoomMutationVariables>(EditRoomDocument, variables, options);
    },
    giftcardCheckout(variables: GiftcardCheckoutMutationVariables, options?: C): Promise<GiftcardCheckoutMutation> {
      return requester<GiftcardCheckoutMutation, GiftcardCheckoutMutationVariables>(GiftcardCheckoutDocument, variables, options);
    },
    giftcardCreate(variables: GiftcardCreateMutationVariables, options?: C): Promise<GiftcardCreateMutation> {
      return requester<GiftcardCreateMutation, GiftcardCreateMutationVariables>(GiftcardCreateDocument, variables, options);
    },
    giftcardResend(variables: GiftcardResendMutationVariables, options?: C): Promise<GiftcardResendMutation> {
      return requester<GiftcardResendMutation, GiftcardResendMutationVariables>(GiftcardResendDocument, variables, options);
    },
    giftcardUpdate(variables: GiftcardUpdateMutationVariables, options?: C): Promise<GiftcardUpdateMutation> {
      return requester<GiftcardUpdateMutation, GiftcardUpdateMutationVariables>(GiftcardUpdateDocument, variables, options);
    },
    Login(variables: LoginMutationVariables, options?: C): Promise<LoginMutation> {
      return requester<LoginMutation, LoginMutationVariables>(LoginDocument, variables, options);
    },
    moveExtra(variables: MoveExtraMutationVariables, options?: C): Promise<MoveExtraMutation> {
      return requester<MoveExtraMutation, MoveExtraMutationVariables>(MoveExtraDocument, variables, options);
    },
    moveRoom(variables: MoveRoomMutationVariables, options?: C): Promise<MoveRoomMutation> {
      return requester<MoveRoomMutation, MoveRoomMutationVariables>(MoveRoomDocument, variables, options);
    },
    ReservationRebook(variables: ReservationRebookMutationVariables, options?: C): Promise<ReservationRebookMutation> {
      return requester<ReservationRebookMutation, ReservationRebookMutationVariables>(ReservationRebookDocument, variables, options);
    },
    reservationRefund(variables: ReservationRefundMutationVariables, options?: C): Promise<ReservationRefundMutation> {
      return requester<ReservationRefundMutation, ReservationRefundMutationVariables>(ReservationRefundDocument, variables, options);
    },
    setWaiter(variables: SetWaiterMutationVariables, options?: C): Promise<SetWaiterMutation> {
      return requester<SetWaiterMutation, SetWaiterMutationVariables>(SetWaiterDocument, variables, options);
    },
    setAdminNote(variables: SetAdminNoteMutationVariables, options?: C): Promise<SetAdminNoteMutation> {
      return requester<SetAdminNoteMutation, SetAdminNoteMutationVariables>(SetAdminNoteDocument, variables, options);
    },
    UpdatePromotion(variables: UpdatePromotionMutationVariables, options?: C): Promise<UpdatePromotionMutation> {
      return requester<UpdatePromotionMutation, UpdatePromotionMutationVariables>(UpdatePromotionDocument, variables, options);
    },
    UpdateSetting(variables: UpdateSettingMutationVariables, options?: C): Promise<UpdateSettingMutation> {
      return requester<UpdateSettingMutation, UpdateSettingMutationVariables>(UpdateSettingDocument, variables, options);
    },
    updateSlots(variables: UpdateSlotsMutationVariables, options?: C): Promise<UpdateSlotsMutation> {
      return requester<UpdateSlotsMutation, UpdateSlotsMutationVariables>(UpdateSlotsDocument, variables, options);
    },
    userCancelReservation(variables: UserCancelReservationMutationVariables, options?: C): Promise<UserCancelReservationMutation> {
      return requester<UserCancelReservationMutation, UserCancelReservationMutationVariables>(UserCancelReservationDocument, variables, options);
    },
    waiterUpdate(variables: WaiterUpdateMutationVariables, options?: C): Promise<WaiterUpdateMutation> {
      return requester<WaiterUpdateMutation, WaiterUpdateMutationVariables>(WaiterUpdateDocument, variables, options);
    },
    Admin(variables: AdminQueryVariables, options?: C): Promise<AdminQuery> {
      return requester<AdminQuery, AdminQueryVariables>(AdminDocument, variables, options);
    },
    Admins(variables?: AdminsQueryVariables, options?: C): Promise<AdminsQuery> {
      return requester<AdminsQuery, AdminsQueryVariables>(AdminsDocument, variables, options);
    },
    bookedReservations(variables: BookedReservationsQueryVariables, options?: C): Promise<BookedReservationsQuery> {
      return requester<BookedReservationsQuery, BookedReservationsQueryVariables>(BookedReservationsDocument, variables, options);
    },
    Checkout(variables?: CheckoutQueryVariables, options?: C): Promise<CheckoutQuery> {
      return requester<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, variables, options);
    },
    Dashboard(variables?: DashboardQueryVariables, options?: C): Promise<DashboardQuery> {
      return requester<DashboardQuery, DashboardQueryVariables>(DashboardDocument, variables, options);
    },
    Extra(variables: ExtraQueryVariables, options?: C): Promise<ExtraQuery> {
      return requester<ExtraQuery, ExtraQueryVariables>(ExtraDocument, variables, options);
    },
    Extras(variables?: ExtrasQueryVariables, options?: C): Promise<ExtrasQuery> {
      return requester<ExtrasQuery, ExtrasQueryVariables>(ExtrasDocument, variables, options);
    },
    Giftcard(variables: GiftcardQueryVariables, options?: C): Promise<GiftcardQuery> {
      return requester<GiftcardQuery, GiftcardQueryVariables>(GiftcardDocument, variables, options);
    },
    GiftcardShow(variables: GiftcardShowQueryVariables, options?: C): Promise<GiftcardShowQuery> {
      return requester<GiftcardShowQuery, GiftcardShowQueryVariables>(GiftcardShowDocument, variables, options);
    },
    Giftcards(variables?: GiftcardsQueryVariables, options?: C): Promise<GiftcardsQuery> {
      return requester<GiftcardsQuery, GiftcardsQueryVariables>(GiftcardsDocument, variables, options);
    },
    Promotion(variables: PromotionQueryVariables, options?: C): Promise<PromotionQuery> {
      return requester<PromotionQuery, PromotionQueryVariables>(PromotionDocument, variables, options);
    },
    Promotions(variables?: PromotionsQueryVariables, options?: C): Promise<PromotionsQuery> {
      return requester<PromotionsQuery, PromotionsQueryVariables>(PromotionsDocument, variables, options);
    },
    Reservation(variables: ReservationQueryVariables, options?: C): Promise<ReservationQuery> {
      return requester<ReservationQuery, ReservationQueryVariables>(ReservationDocument, variables, options);
    },
    ReservationShow(variables: ReservationShowQueryVariables, options?: C): Promise<ReservationShowQuery> {
      return requester<ReservationShowQuery, ReservationShowQueryVariables>(ReservationShowDocument, variables, options);
    },
    Reservations(variables?: ReservationsQueryVariables, options?: C): Promise<ReservationsQuery> {
      return requester<ReservationsQuery, ReservationsQueryVariables>(ReservationsDocument, variables, options);
    },
    ReservationsByDate(variables: ReservationsByDateQueryVariables, options?: C): Promise<ReservationsByDateQuery> {
      return requester<ReservationsByDateQuery, ReservationsByDateQueryVariables>(ReservationsByDateDocument, variables, options);
    },
    Room(variables: RoomQueryVariables, options?: C): Promise<RoomQuery> {
      return requester<RoomQuery, RoomQueryVariables>(RoomDocument, variables, options);
    },
    Rooms(variables?: RoomsQueryVariables, options?: C): Promise<RoomsQuery> {
      return requester<RoomsQuery, RoomsQueryVariables>(RoomsDocument, variables, options);
    },
    RoomsList(variables: RoomsListQueryVariables, options?: C): Promise<RoomsListQuery> {
      return requester<RoomsListQuery, RoomsListQueryVariables>(RoomsListDocument, variables, options);
    },
    Setting(variables: SettingQueryVariables, options?: C): Promise<SettingQuery> {
      return requester<SettingQuery, SettingQueryVariables>(SettingDocument, variables, options);
    },
    Settings(variables?: SettingsQueryVariables, options?: C): Promise<SettingsQuery> {
      return requester<SettingsQuery, SettingsQueryVariables>(SettingsDocument, variables, options);
    },
    RoomSlots(variables: RoomSlotsQueryVariables, options?: C): Promise<RoomSlotsQuery> {
      return requester<RoomSlotsQuery, RoomSlotsQueryVariables>(RoomSlotsDocument, variables, options);
    },
    Taxes(variables?: TaxesQueryVariables, options?: C): Promise<TaxesQuery> {
      return requester<TaxesQuery, TaxesQueryVariables>(TaxesDocument, variables, options);
    },
    Waiter(variables: WaiterQueryVariables, options?: C): Promise<WaiterQuery> {
      return requester<WaiterQuery, WaiterQueryVariables>(WaiterDocument, variables, options);
    },
    Waiters(variables?: WaitersQueryVariables, options?: C): Promise<WaitersQuery> {
      return requester<WaitersQuery, WaitersQueryVariables>(WaitersDocument, variables, options);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;