





import { Component, Vue } from 'vue-property-decorator'
import CartStore from '@/store/modules/cart'
import 'vue-select/dist/vue-select.css'
@Component({ components: { } })
export default class App extends Vue {
  mounted () {
    CartStore.load()
  }
}
