

























import { Component, Vue } from 'vue-property-decorator'
import extrasGQL from '@/graphql/queries/extras.graphql'
import DeleteExtraGQL from '@/graphql/mutations/delete_extra.graphql'
import Client, { sdk } from '@/graphql/client'
import max from 'lodash/max'
import swal from 'sweetalert2'
import {
  ExtrasQuery,
  ExtrasQueryVariables,
  DeleteExtraMutation,
  DeleteExtraMutationVariables
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  extras: ExtrasQuery['extras'] = [];
  fields = ['image', 'title', 'price', 'position', 'actions'];
  loading = false;
  mounted () {
    this.loadExtras()
  }

  get maxPosition (): number {
    return max(this.extras.map((extra) => extra.position))
  }

  async move (id: number, position: string) {
    await sdk.moveExtra({ id, position: Number(position) })
  }

  async loadExtras () {
    this.loading = true
    const response = await Client.query<ExtrasQuery, ExtrasQueryVariables>(
      extrasGQL
    )
    this.extras = response.extras
    this.loading = false
  }

  async deleteExtra (id) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      text: 'Once deleted, you will not be able to recover this extra',
      icon: 'warning',
      showCancelButton: true
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await Client.query<DeleteExtraMutation, DeleteExtraMutationVariables>(
      DeleteExtraGQL,
      { extra: id }
    )
    await this.loadExtras()
  }

  image (image) {
    return image?.variants?.thumb || image?.path
  }
}
