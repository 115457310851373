







import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import AdminForm from './Form.vue'
import { CreateAdminMutationVariables } from '@/generated/graphql'
@Component({ components: { AdminForm } })
export default class CreateAdmin extends Vue {
  saving = false

  async save (payload: CreateAdminMutationVariables['admin']) {
    this.saving = true
    await sdk.CreateAdmin({ admin: payload })
    this.saving = false
    this.$router.push({ name: 'admins' })
  }
}
