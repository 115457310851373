















import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { Maybe, PromotionQuery } from '@/generated/graphql'

@Component({ components: {} })
export default class Form extends Vue {
  process = false

  @Prop() promotion: Maybe<PromotionQuery['promotion']>;
  @Prop() saving;

  get processing () {
    return this.saving
  }

  get options () {
    return [
      { value: 0, text: 'Sunday' },
      { value: 1, text: 'Monday' },
      { value: 2, text: 'Tuesday' },
      { value: 3, text: 'Wednesday' },
      { value: 4, text: 'Thursday' },
      { value: 5, text: 'Friday' },
      { value: 6, text: 'Saturday' }
    ]
  }

  form = {
    dayOfWeek: 0,
    reduction: 10
  } as { dayOfWeek: number | string, reduction: number | string};

  validations () {
    return {
      form: {
        dayOfWeek: { required },
        reduction: { required }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.promotion) {
      this.form.dayOfWeek = this.promotion.dayOfWeek
      this.form.reduction = this.promotion.reduction
    }
  }

  async submit () {
    this.process = true
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return
    this.form.dayOfWeek = Number(this.form.dayOfWeek)
    this.form.reduction = Number(this.form.reduction)
    this.$emit('save', this.form)
    this.process = false
  }
}
