







import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import SettingForm from './Form.vue'

@Component({ components: { SettingForm } })
export default class createSetting extends Vue {
  saving = false;
  async save (payload) {
    this.saving = true
    await sdk.CreateSetting({ setting: payload })
    this.saving = false
    this.$router.push({
      name: 'settings'
    })
  }
}
