




























import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import ImageUpload from '@/components/ImageUpload.vue'
import { RoomStatus, RoomQuery, Maybe } from '@/generated/graphql'
import startCase from 'lodash/startCase'

@Component({ components: { ImageUpload } })
export default class Form extends Vue {
  uploading = false;
  imageCount = 0
  process = false
  images: string[] = []

  @Prop() room: Maybe<RoomQuery['room']>;
  @Prop() saving;

  get processing () {
    return this.uploading || this.saving
  }

  get statusOptions () {
    return Object.values(RoomStatus)
      .map(value => ({ text: startCase(value.toLowerCase()), value }))
  }

  form = {
    title: '',
    description: '',
    people: 6,
    status: RoomStatus.Draft,
    images: {
      create: [] as { path: string, order: number }[]
    }
  };

  validations () {
    return {
      form: {
        title: { required },
        description: { required }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.room) {
      this.images = this.room.images.map(i => i.path)
      this.form.title = this.room.title
      this.form.description = this.room.description || ''
      this.form.people = this.room.people
      this.form.status = this.room.status
    }
  }

  async submit () {
    this.$v.form.$touch()
    this.uploading = true
    this.process = true
  }

  async save ({ files, paths }) {
    this.form.images = {
      create: files.map((file, i) => {
        const path = this.path(file)
        return {
          path,
          order: i,
          variants: paths.find(i => i.original === path)
        }
      })
    }

    if (this.$v.form.$anyError) return
    this.uploading = false
    this.$emit('save', this.form)
    this.process = false
  }

  path (file) {
    return file.serverId.startsWith('http')
      ? file.serverId
      : `https://lalarooms.s3.amazonaws.com/${file.serverId}`
  }
}
