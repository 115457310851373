























import { Component, Prop, Vue } from 'vue-property-decorator'
import Client, { sdk } from '@/graphql/client'
import ReservationsGQL from '@/graphql/queries/reservations.graphql'
import { Calendar, EventInput, EventClickArg } from '@fullcalendar/core'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import { ReservationsQuery, ReservationsQueryVariables, BookedReservationsQuery, Maybe, ReservationStatus, WaitersQuery, Role } from '@/generated/graphql'
import ReservationModal from './ReservationModal.vue'
import SlotModal from './SlotModal.vue'
import SlotRebookModal from './SlotRebookModal.vue'
import { conflict } from '../../lib/utils'
import { format, parse } from 'date-fns'
import Swal from 'sweetalert2'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import parsePhoneNumber from 'libphonenumber-js'

@Component({ components: { ReservationModal, SlotModal, SlotRebookModal } })
export default class Reservations extends Vue {
  @Prop() date!: string
  @Prop({ default: false }) isRebook!: boolean
  @Prop({ required: false }) editingId
  @Prop({ required: false }) originalSlotCost!: number
  @Prop({ required: false }) originalBookedDate

  calendar: Calendar | null = null;

  rooms: NonNullable<ReservationsQuery['rooms']> = []
  viewing: Maybe<EventClickArg['event']> = null
  currentReservations: BookedReservationsQuery['reservations'] = []
  selectedSlots: ReservationsQuery['rooms'][0]['timeSlots'] = []
  waiters: WaitersQuery['waiters'] = []

  async events (info, success) {
    const newDate = info.startStr.split('T')[0]
    if (this.currentDate !== newDate) this.$router.push({ params: { date: newDate } })
    const reservations = await this.reservationsFor(newDate)
    const slots = this.slotsFor(this.currentDate).filter((slot) => {
      for (const reservation of reservations) {
        if (this.conflicts(reservation, slot)) { return false }
      }
      return true
    })
    this.$bvModal.hide('viewSlot')
    this.$bvModal.hide('viewReservation')
    return success(reservations.concat(slots))
  }

  get currentDate (): string {
    return this.date || format(new Date(), 'yyyy-MM-dd')
  }

  reload () {
    if (this.isRebook) {
      this.$bvModal.hide('viewRebookSlot')
      this.$emit('reload')
    }
    this.selectedSlots = []
    this.calendar!.refetchEvents()
  }

  conflicts (reservation: EventInput, slot: EventInput) {
    if (reservation.resourceId !== slot.resourceId) return false
    const times = {
      startA: slot.startTime,
      endA: slot.endTime,
      startB: reservation.startTime,
      endB: reservation.endTime
    }
    return conflict(times as any)
  }

  slotsFor (date: string): EventInput[] {
    let desiredDayOfWeek = Number(format(parse(date, 'yyyy-MM-dd', new Date()), 'i'))
    desiredDayOfWeek = desiredDayOfWeek === 7 ? 0 : desiredDayOfWeek
    return this.rooms.flatMap((room) => {
      return room!.timeSlots
        .filter((slot) => {
          return slot!.dayOfWeek === desiredDayOfWeek
        }).map((slot) => {
          return {
            id: slot!.id.toString(),
            resourceId: room!.id.toString(),
            title: `(Not Booked) - $${slot.price}`,
            startTime: slot!.startTime,
            endTime: slot!.endTime,
            extendedProps: {
              slot,
              room
            }
          }
        })
    })
  }

  get slots () {
    return this.rooms.flatMap((room) => {
      return room!.timeSlots
    })
  }

  get totalCost () {
    return this.selectedSlots.reduce((acc, slot) => {
      return acc + slot!.price
    }, 0)
  }

  async reservationsFor (date: string): Promise<EventInput[]> {
    this.currentReservations = (await (sdk.bookedReservations({ date, statuses: [ReservationStatus.Paid, ReservationStatus.Manual] }))).reservations
    return this.currentReservations.flatMap((reservation) => reservation!.reservationTimeSlots).map((slot) => {
      return {
        id: slot!.id.toString(),
        resourceId: slot!.room.id.toString(),
        startTime: slot!.startTime,
        endTime: slot!.endTime,
        backgroundColor: 'red',
        extendedProps: {
          reservation: this.currentReservations.find((reservation) => reservation!.id === slot!.reservation.id)
        }
      }
    })
  }

  book () {
    const modal = this.isRebook ? 'viewRebookSlot' : 'viewSlot'
    this.$bvModal.show(modal)
  }

  parsedNumber (phoneNumber) {
    if (!phoneNumber) return 'No # on file.'
    const parsed = parsePhoneNumber(phoneNumber)
    if (!parsed) return phoneNumber
    return parsed?.formatInternational()
  }

  async mounted () {
    this.rooms = (await Client.query<ReservationsQuery, ReservationsQueryVariables>(ReservationsGQL)).rooms
    this.waiters = (await sdk.Waiters()).waiters
    this.$nextTick(() => {
      this.calendar = new Calendar(this.$refs.calendar as HTMLElement, {
        plugins: [resourceTimeGridPlugin],
        initialView: 'resourceTimeGridDay',
        initialDate: this.currentDate,
        dayHeaderFormat: { weekday: 'short' },
        editable: true,
        height: 'calc(100vh - 200px)',
        customButtons: {
          jump: {
            text: 'Jump to date',
            click: () => {
              Swal.fire({
                title: 'Jump to date',
                html: '<input class="swal2-input" id="jump-to">',
                stopKeydownPropagation: false,
                willOpen: () => {
                  flatpickr(Swal.getPopup()!.querySelector('#jump-to')!, {
                    defaultDate: this.currentDate,
                    altInput: true,
                    altFormat: 'm/d/Y',
                    onChange: (event) => {
                      this.calendar!.gotoDate(event[0])
                    }
                  })
                }
              })
            }
          }
        },
        headerToolbar: {
          left: 'title',
          center: '',
          right: 'prev,next today jump'
        },
        eventContent: (e) => {
          const event = e.event
          if (event.extendedProps.reservation) {
            let html = `<b>BOOKED</b><br /><span><i class="bi bi-person"></i>${event.extendedProps.reservation.client.name}</span><br /><span><i class="bi bi-phone"></i>${this.parsedNumber(event.extendedProps.reservation.phoneNumber)}</span>`
            if (event.extendedProps.reservation.waiter) {
              html += `<br /><i class="bi bi-at"></i><span>${event.extendedProps.reservation.waiter.firstname} ${event.extendedProps.reservation.waiter.lastname} ($${event.extendedProps.reservation.gratuity / 100})</span>`
            }
            return { html }
          } else {
            return { html: `<b>(AVAILABLE)</b><br /><span>$${event.extendedProps.slot.price}</span>` }
          }
        },
        events: this.events,
        resources: this.rooms.map((room) => {
          return { id: room!.id.toString(), title: room!.title }
        }),
        datesSet: () => {
          this.selectedSlots = []
        },
        eventClick: (info) => {
          this.viewing = info.event
          if (info.event.extendedProps.slot) {
            if (this.$currentUser.role === Role.User) return
            if (this.selectedSlots.find((slot) => slot.id === info.event.extendedProps.slot.id)) {
              info.event.setProp('backgroundColor', null)
              this.selectedSlots = this.selectedSlots.filter(slot => info.event.extendedProps.slot.id !== slot.id)
            } else {
              info.event.setProp('backgroundColor', '#ff966f')
              const slot = this.slots.find((slot) => slot!.id === info.event.extendedProps.slot.id)!
              this.selectedSlots.push(slot)
            }
          } else {
            this.$bvModal.show('viewReservation')
          }
        },
        views: {
          resourceTimeGridDay: {
            allDaySlot: false,
            slotMinTime: '10:00:00',
            slotMaxTime: '29:00:00'
          }
        }
      })
      this.calendar.render()
    })
  }
}
