

























import { Component, Vue } from 'vue-property-decorator'
import 'animate.css'
import { Role } from './generated/graphql'
@Component({ components: {} })
export default class AdminLayout extends Vue {
  signout () {
    window.sessionStorage.removeItem('adminKey')
    this.$router.push({ name: 'login' })
  }

  get isAdmin () {
    return this.$currentUser.role === Role.Admin
  }
}
