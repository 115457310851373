





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { required, email, numeric } from 'vuelidate/lib/validators'
import { Maybe, GiftcardShowQuery, GiftcardAdminInput, Role } from '@/generated/graphql'
@Component({})
export default class Form extends Vue {
  @Prop() giftcard: Maybe<GiftcardShowQuery['giftcard']>;
  @Prop() saving;
  @Prop() error;

  get processing () {
    return this.saving
  }

  get isUser () {
    return this.$currentUser.role === Role.User
  }

  form = {
    recipient: '',
    message: '',
    amount: 100,
    code: '',
    senderName: ''
  } as GiftcardAdminInput;

  pin = null

  validations () {
    if (this.giftcard) {
      return {
        form: {
          recipient: { required, email },
          message: { },
          amount: { required, numeric },
          code: { required },
          senderName: { required }
        }
      }
    } else {
      return {
        form: {
          recipient: { required, email },
          message: { },
          amount: { required, numeric },
          senderName: { required }
        }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.giftcard) {
      this.form.recipient = this.giftcard.recipient
      this.form.message = this.giftcard.message
      this.form.amount = this.giftcard.amount
      this.form.code = this.giftcard.code
      this.form.senderName = this.giftcard.senderName
    }
  }

  get left () {
    if (this.giftcard) {
      const diff = this.giftcard.amount - this.giftcard.left
      return this.form.amount - diff
    } else {
      return this.form.amount
    }
  }

  amountFormatter (val) {
    if (val === '') return this.form.amount
    if (Number(val) > 1000) return '1000'
    if (Number(val) < 1) return '1'

    return val
  }

  async submit () {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return

    const amount = parseInt(this.form.amount as any)
    this.$emit('save', { form: { ...this.form, amount }, pin: this.pin })
  }
}
