


















import { Component, Vue, Prop } from 'vue-property-decorator'
import { ReservationsQuery, ReservationCreateMutation, ReservationCreateMutationVariables, ReservationStatus } from '@/generated/graphql'
import ReservationCreateGQL from '@/graphql/mutations/create_reservation.graphql'
import client from '@/graphql/client'
import { required, email } from 'vuelidate/lib/validators'

@Component({ components: { } })
export default class SlotModal extends Vue {
  @Prop() slots!: ReservationsQuery['rooms'][0]['timeSlots']
  @Prop() totalCost!: number
  @Prop() date!: string

  submitting = false

  form = {
    name: '',
    email: '',
    notes: ''
  }

  validations () {
    return {
      form: {
        name: { required },
        email: { required, email }
      }
    }
  }

  validateState (name) {
    if (!this.$v.form[name]) return
    const $dirty = this.$v.form[name]!.$dirty
    const $error = this.$v.form[name]!.$error
    return $dirty ? !$error : null
  }

  reset () {
    this.$v.$reset()
    this.submitting = false
  }

  async book (event) {
    event.preventDefault()
    this.$v.$touch()
    if (this.$v.$invalid) return

    this.submitting = true
    await client.query<ReservationCreateMutation, ReservationCreateMutationVariables>(ReservationCreateGQL, {
      data: {
        date: this.date,
        status: ReservationStatus.Manual,
        stripeReference: 'manual',
        orderTotal: 0,
        notes: this.form.notes,
        client: {
          name: this.form.name,
          email: this.form.email
        },
        reservationTimeSlots: this.slots.map((slot) => {
          return {
            date: this.date,
            startTime: slot.startTime,
            endTime: slot.endTime,
            price: slot.price,
            roomId: slot.room.id
          }
        })

      }
    })
    this.form = {
      name: '',
      email: '',
      notes: ''
    }
    this.$emit('reload')
  }
}
