import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Cancel from '../views/Cancel.vue'
import Login from '../views/admin/Login.vue'
import Dashboard from '../views/admin/Dashboard.vue'
import Rooms from '../views/admin/Rooms.vue'
import Extras from '../views/admin/Extras.vue'
import CreateRoom from '../views/admin/rooms/Create.vue'
import CreateExtra from '../views/admin/extras/Create.vue'
import EditExtra from '../views/admin/extras/Edit.vue'
import EditRoom from '../views/admin/rooms/Edit.vue'
import Reservations from '../views/admin/Reservations.vue'
import Giftcards from '../views/admin/Giftcards.vue'
import CreateGiftcard from '../views/admin/giftcards/Create.vue'
import EditGiftcard from '../views/admin/giftcards/Edit.vue'
import RoomSlots from '../views/admin/rooms/Slots.vue'
import AdminLayout from '../AdminLayout.vue'

import Waiters from '../views/admin/Waiters.vue'
import CreateWaiter from '../views/admin/waiters/Create.vue'
import EditWaiter from '../views/admin/waiters/Edit.vue'

import Admins from '../views/admin/Admins.vue'
import CreateAdmin from '../views/admin/admins/Create.vue'
import EditAdmin from '../views/admin/admins/Edit.vue'

import TaxReporting from '../views/admin/TaxReporting.vue'

import RebookReservation from '../views/admin/RebookReservation.vue'

import Promotions from '../views/admin/Promotions.vue'
import PromotionCreate from '../views/admin/promotions/Create.vue'
import PromotionEdit from '../views/admin/promotions/Edit.vue'

import Settings from '../views/admin/Settings.vue'
import SettingCreate from '../views/admin/settings/Create.vue'
import SettingEdit from '../views/admin/settings/Edit.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "public" */ '../views/Home.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () =>
      import(/* webpackChunkName: "public" */ '../views/Checkout.vue')
  },
  {
    path: '/giftcard',
    name: 'giftcard',
    component: () =>
      import(/* webpackChunkName: "public" */ '../views/Giftcard.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/cancel/:id',
    name: 'cancel',
    props: true,
    component: Cancel
  },
  {
    path: '/admin',
    component: AdminLayout,
    beforeEnter (to, from, next) {
      if (window.sessionStorage.getItem('adminKey')) next()
      else next({ name: 'login' })
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: 'rooms',
        name: 'rooms',
        component: Rooms
      },
      {
        path: 'rooms/new',
        name: 'createRoom',
        component: CreateRoom
      },
      {
        path: 'rooms/:id/edit',
        name: 'editRoom',
        component: EditRoom,
        props: true
      },
      {
        path: 'rooms/:id/slots',
        name: 'roomSlots',
        props: true,
        component: RoomSlots
      },
      {
        path: 'extras',
        name: 'extras',
        component: Extras
      },
      {
        path: 'extras/new',
        name: 'createExtra',
        component: CreateExtra
      },
      {
        path: 'extras/:id/edit',
        name: 'editExtra',
        component: EditExtra,
        props: true
      },
      {
        path: 'reservations/:date?',
        name: 'reservations',
        component: Reservations,
        props: true
      },
      {
        path: 'giftcards',
        name: 'giftcards',
        component: Giftcards
      },
      {
        path: 'giftcards/:id/edit',
        name: 'editGiftcard',
        component: EditGiftcard,
        props: true
      },
      {
        path: 'giftcards/new',
        name: 'createGiftcard',
        component: CreateGiftcard,
        props: true
      },
      {
        path: 'waiters',
        name: 'waiters',
        component: Waiters
      },
      {
        path: 'waiters/new',
        name: 'createWaiter',
        component: CreateWaiter
      },
      {
        path: 'waiters/:id/edit',
        name: 'editWaiter',
        component: EditWaiter,
        props: true
      },
      {
        path: 'admins',
        name: 'admins',
        component: Admins
      },
      {
        path: 'admins/new',
        name: 'createAdmin',
        component: CreateAdmin
      },
      {
        path: 'admins/:id/edit',
        name: 'editAdmin',
        component: EditAdmin,
        props: true
      },
      {
        path: 'rebook_reservation/:id/:date?',
        name: 'rebook_reservation',
        component: RebookReservation,
        props: true
      },
      {
        path: 'tax_reporting/:month?',
        name: 'taxReporting',
        props: true,
        component: TaxReporting
      },
      {
        path: 'promotions',
        name: 'promotions',
        component: Promotions
      },
      {
        path: 'promotions/new',
        name: 'createPromotion',
        component: PromotionCreate
      },
      {
        path: 'promotions/:id/edit',
        name: 'editPromotion',
        component: PromotionEdit,
        props: true
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings
      },
      {
        path: 'settings/new',
        name: 'createSetting',
        component: SettingCreate
      },
      {
        path: 'settings/:settingKey/edit',
        name: 'editSetting',
        component: SettingEdit,
        props: true
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
