







import { Component, Vue, Prop } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  Maybe,
  SettingKey,
  SettingQuery
} from '@/generated/graphql'
import SettingForm from './Form.vue'
@Component({ components: { SettingForm } })
export default class EditSetting extends Vue {
  saving = false;
  setting: Maybe<SettingQuery['setting']> = null;

  @Prop({ required: true }) settingKey!: SettingKey

  async mounted () {
    this.setting = (await sdk.Setting({ key: this.settingKey })).setting
  }

  async save (payload) {
    this.saving = true
    await sdk.UpdateSetting(
      { setting: payload }
    )
    this.saving = false
    swal.fire('Awesome!', 'setting has been updated!', 'success')
    this.$router.push({
      name: 'settings'
    })
  }
}
