











import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { RecurringEvent } from '@/types'
import { parseTimeToDate } from '@/lib/utils'
import { format } from 'date-fns'

@Component({ components: {} })
export default class SlotModal extends Vue {
  @Prop() event!: RecurringEvent;
  priceBuffer = 0;

  setup () {
    this.priceBuffer = this.event.extendedProps?.price
  }

  priceFormatter (value) {
    return `$${value}`
  }

  get EditModalTitle () {
    if (!this.event) return ''
    const startTime = format(parseTimeToDate(this.event.startTime), 'h:mm a')
    const endTime = format(parseTimeToDate(this.event.endTime), 'h:mm a')
    return `${startTime} - ${endTime}`
  }

  get persisted () {
    if (!this.event) return false
    return this.event.extendedProps?.new !== true
  }

  editEvent () {
    this.$emit('edit', Object.assign({}, this.event, { title: `$${this.priceBuffer}`, extendedProps: { price: this.priceBuffer } }) as RecurringEvent)
  }

  remove (): void {
    if (!this.event) return
    this.$emit('remove', this.event.id)
  }

  cancel (): void {
    if (this.event.extendedProps.new) this.$emit('remove', this.event.id)
  }

  @Watch('event')
  onEventChange () {
    (this as any).$bvModal.show('EditSlot')
  }
}
