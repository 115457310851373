
























import { Component, Vue, Prop } from 'vue-property-decorator'
import { email, minLength, numeric, required } from 'vuelidate/lib/validators'
import { Maybe, Role, AdminCreateInput, AdminQuery } from '@/generated/graphql'
@Component({})
export default class Form extends Vue {
  @Prop() admin: Maybe<AdminQuery['admin']>;
  @Prop() saving;

  get processing () {
    return this.saving
  }

  form = {
    email: '',
    password: '',
    role: Role.User,
    pin: ''
  }

  get roleOptions () {
    return Object.entries(Role).map(([key, value]) => {
      return {
        text: key,
        value
      }
    })
  }

  get isUser () {
    return this.form.role === Role.User
  }

  get passwordValidations () {
    if (!this.admin || this.form.password !== '') {
      return {
        required: !this.admin,
        minLength: minLength(8),
        containsUppercase: function (value: string) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function (value: string) {
          return /[a-z]/.test(value)
        },
        containsNumber: function (value: string) {
          return /[0-9]/.test(value)
        },
        containsSpecial: function (value: string) {
          return /[#?!@$%^&*-]/.test(value)
        }
      }
    } else {
      return {}
    }
  }

  validations () {
    return {
      form: {
        email: { required, email },
        password: this.passwordValidations,
        role: { required },
        pin: !this.isUser ? { required, minLength: minLength(4), numeric } : {}
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.admin) {
      this.form.email = this.admin.email
      this.form.role = this.admin.role
      this.form.pin = String(this.admin.pin)
    }
  }

  async submit () {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return

    const payload: AdminCreateInput = {
      email: this.form.email,
      password: this.form.password,
      role: this.form.role,
      pin: this.form.pin ? Number(this.form.pin) : null
    }

    this.$emit('save', payload)
  }
}
