






















import { Component, Vue, Watch } from 'vue-property-decorator'
import waitersGQL from '@/graphql/queries/waiters.graphql'
import DeleteGQL from '@/graphql/mutations/delete_waiter.graphql'
import Client from '@/graphql/client'
import swal from 'sweetalert2'
import {
  DeleteWaiterMutation,
  DeleteWaiterMutationVariables,
  WaitersQuery,
  WaitersQueryVariables
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  waiters: WaitersQuery['waiters'] = [];
  fields = ['firstname', 'lastname', 'actions'];

  loading = false;
  mounted () {
    this.loadWaiters()
  }

  async loadWaiters () {
    this.loading = true
    const response = await Client.query<WaitersQuery, WaitersQueryVariables>(
      waitersGQL
    )
    this.waiters = response.waiters
    this.loading = false
  }

  async deleteWaiter (waiterId: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      text: 'Once deleted, you will not be able to recover this extra',
      icon: 'warning',
      showCancelButton: true
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await Client.query<DeleteWaiterMutation, DeleteWaiterMutationVariables>(
      DeleteGQL,
      { id: waiterId }
    )
    await this.loadWaiters()
  }

  @Watch('filters', { deep: true })
  onFilterChange () {
    this.loadWaiters()
  }
}
