































import { Component, Vue } from 'vue-property-decorator'
import roomsGQL from '@/graphql/queries/rooms.graphql'
import DeleteRoomGQL from '@/graphql/mutations/delete_room.graphql'
import Client, { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  RoomsQuery,
  RoomsQueryVariables,
  DeleteRoomMutation,
  DeleteRoomMutationVariables
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  rooms: RoomsQuery['rooms'] = [];
  fields = ['image', 'title', 'people', 'status', 'position', 'actions'];
  loading = false;
  mounted () {
    this.loadRooms()
  }

  async loadRooms () {
    this.loading = true
    const response = await Client.query<RoomsQuery, RoomsQueryVariables>(
      roomsGQL
    )
    this.rooms = response.rooms
    this.loading = false
  }

  imageUrl (room: NonNullable<RoomsQuery['rooms']>[0]) {
    const image = room.images[0]
    if (!image) return null
    return image.variants?.thumb || image.path
  }

  move (id, position) {
    position = Number(position)
    sdk.moveRoom({ id, position })
  }

  async deleteRoom (id) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text:
          "Once deleted, you will not be able to recover this room or it's timeslots!",
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await Client.query<DeleteRoomMutation, DeleteRoomMutationVariables>(
      DeleteRoomGQL,
      { room: id }
    )
    await this.loadRooms()
  }
}
