






































import { Component, Vue, Prop } from 'vue-property-decorator'
import reservationGQL from '@/graphql/queries/reservation.graphql'
import cancelGQL from '@/graphql/mutations/user_cancel_reservation.graphql'
import client from '@/graphql/client'
import { ReservationQuery, ReservationQueryVariables, ReservationStatus, UserCancelReservationMutation, UserCancelReservationMutationVariables } from '@/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { format, isAfter, addHours, parseISO } from 'date-fns'
import Swal from 'sweetalert2'

@Component({ components: { } })
export default class Cancel extends Vue {
  @Prop() id!: string
  loaded = false
  canceling = false
  reservation: Maybe<ReservationQuery['ownReservation']> = null
  async mounted () {
    this.reservation = (await client.query<ReservationQuery, ReservationQueryVariables>(reservationGQL, { reference: this.id })).ownReservation
    this.loaded = true
  }

  get dateObject () {
    if (!this.reservation) return new Date()
    const firstSlot = this.reservation.reservationTimeSlots.sort((a, b) => {
      return Number(a.startTime.replace(':', '')) - Number(b.startTime.replace(':', ''))
    })[0]
    const dateWithTime = `${firstSlot.date.split('T')[0]}T${firstSlot.startTime}-0400`
    return parseISO(dateWithTime)
  }

  get date () {
    return format(this.dateObject, 'PPPP')
  }

  get notFound () {
    return this.loaded && !this.reservation
  }

  get paid () {
    return !this.notFound && this.reservation!.status === ReservationStatus.Paid
  }

  get cancelable () {
    return this.paid && isAfter(this.dateObject, addHours(new Date(), 48))
  }

  get late () {
    return this.paid && !this.cancelable
  }

  async cancel () {
    if (!this.reservation) return
    this.canceling = true
    const response = (await client.query<UserCancelReservationMutation, UserCancelReservationMutationVariables>(cancelGQL, { reference: this.id })).userReservationCancel
    if (response) {
      Swal.fire('Cancelation confirmed', `A refund of $${response.amount / 100} was issued on the card ending with ***${response.last4}`, 'success')
      this.$router.push({ name: 'Home' })
    } else {
      Swal.fire('Error', 'There was an issue while canceling your reservation. Please try again later or contact us directly at (727) 329 9455', 'error')
    }
    this.canceling = false
  }
}
