

























import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  SettingKey,
  SettingsQuery
} from '@/generated/graphql'
@Component({ components: {} })
export default class Settings extends Vue {
  settings: SettingsQuery['settings'] = [];
  fields = ['key', 'value', 'actions'];
  loading = false;
  mounted () {
    this.loadSettings()
  }

  async loadSettings () {
    this.loading = true
    const response = await sdk.Settings()
    this.settings = response.settings
    this.loading = false
  }

  key (key: SettingKey): string {
    switch (key) {
      case SettingKey.CtaText:
        return 'Prmotion Banner'
    }
  }

  async deleteSetting (id: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text:
          'Once deleted, you will not be able to recover this setting',
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await sdk.DeleteSetting({ setting: id })
    await this.loadSettings()
  }
}
