







import { Component, Vue } from 'vue-property-decorator'
import createGiftcardGQL from '@/graphql/mutations/giftcard_create.graphql'
import Client from '@/graphql/client'
import {
  GiftcardCreateMutation,
  GiftcardCreateMutationVariables
} from '@/generated/graphql'
import GiftcardForm from './Form.vue'
import { Maybe } from 'graphql/jsutils/Maybe'
@Component({ components: { GiftcardForm } })
export default class CreateGiftcard extends Vue {
  saving = false
  error: Maybe<string> = null

  async save (payload) {
    this.saving = true
    try {
      await Client.query<GiftcardCreateMutation, GiftcardCreateMutationVariables>(
        createGiftcardGQL,
        {
          input: payload.form,
          pin: parseInt(payload.pin)
        }
      )
      this.$router.push({ name: 'giftcards' })
    } catch (e: any) {
      if (e.message.includes('Not Authorized')) {
        this.error = e.message
      }
    }
    this.saving = false
  }
}
