







import { Component, Vue, Prop } from 'vue-property-decorator'
import updateGQL from '@/graphql/mutations/waiter_update.graphql'
import waiterGQL from '@/graphql/queries/waiter.graphql'
import Client from '@/graphql/client'
import {
  Maybe,
  WaiterQuery,
  WaiterQueryVariables,
  WaiterUpdateMutation,
  WaiterUpdateMutationVariables
} from '@/generated/graphql'
import WaiterForm from './Form.vue'
@Component({ components: { WaiterForm } })
export default class EditWaiter extends Vue {
  @Prop() id!: string;
  saving = false;
  waiter: Maybe<WaiterQuery['waiter']> = null;

  async mounted () {
    this.waiter = (
      await Client.query<WaiterQuery, WaiterQueryVariables>(waiterGQL, {
        id: parseInt(this.id)
      })
    ).waiter
  }

  async save (payload) {
    this.saving = true
    await Client.query<WaiterUpdateMutation, WaiterUpdateMutationVariables>(
      updateGQL,
      {
        id: parseInt(this.id),
        input: payload
      }
    )
    this.saving = false
    this.$router.push({ name: 'waiters' })
  }
}
