







import { Component, Vue, Prop } from 'vue-property-decorator'
import RoomGQL from '@/graphql/queries/room.graphql'
import EditRoomGQL from '@/graphql/mutations/edit_room.graphql'
import Client from '@/graphql/client'
import swal from 'sweetalert2'
import {
  RoomQuery,
  RoomQueryVariables,
  Maybe,
  EditRoomMutation,
  EditRoomMutationVariables
} from '@/generated/graphql'
import RoomForm from './Form.vue'
@Component({ components: { RoomForm } })
export default class EditRoom extends Vue {
  saving = false;
  room: Maybe<RoomQuery['room']> = null;

  @Prop({ required: true }) id!: string

  async mounted () {
    this.room = (
      await Client.query<RoomQuery, RoomQueryVariables>(RoomGQL, {
        id: parseInt(this.id)
      })
    ).room
  }

  async save (payload) {
    this.saving = true
    await Client.query<EditRoomMutation, EditRoomMutationVariables>(
      EditRoomGQL,
      { id: parseInt(this.id), room: payload }
    )
    this.saving = false
    swal.fire('Awesome!', `${this.room!.title} has been updated!`, 'success')
    this.$router.push({
      name: 'rooms'
    })
  }
}
