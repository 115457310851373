







import { Component, Vue } from 'vue-property-decorator'
import createExtraGQL from '@/graphql/mutations/create_extra.graphql'
import Client from '@/graphql/client'
import {
  CreateExtraMutation,
  CreateExtraMutationVariables
} from '@/generated/graphql'
import ExtraForm from './Form.vue'
@Component({ components: { ExtraForm } })
export default class CreateExtra extends Vue {
  saving = false

  async save (payload) {
    this.saving = true
    await Client.query<CreateExtraMutation, CreateExtraMutationVariables>(
      createExtraGQL,
      {
        extra: payload
      }
    )
    this.saving = false
    this.$router.push({ name: 'extras' })
  }
}
