







import { Component, Vue } from 'vue-property-decorator'
import createRoomGQL from '@/graphql/mutations/create_room.graphql'
import Client from '@/graphql/client'
import {
  CreateRoomMutation,
  CreateRoomMutationVariables
} from '@/generated/graphql'
import RoomForm from './Form.vue'
@Component({ components: { RoomForm } })
export default class CreateRoom extends Vue {
  saving = false;
  async save (payload) {
    this.saving = true
    const room = await Client.query<
      CreateRoomMutation,
      CreateRoomMutationVariables
    >(createRoomGQL, {
      room: payload
    })
    this.saving = false
    this.$router.push({
      name: 'roomSlots',
      params: { id: room.roomCreate!.id.toString() }
    })
  }
}
