
























import { Component, Vue } from 'vue-property-decorator'
import swal from 'sweetalert2'
import {
  AdminsQuery,
  Role
} from '@/generated/graphql'
import { sdk } from '@/graphql/client'
@Component({ components: {} })
export default class Admins extends Vue {
  admins: AdminsQuery['admins'] = [];
  fields = ['email', 'role', 'actions'];

  loading = false;
  mounted () {
    this.loadAdmins()
  }

  async loadAdmins () {
    this.loading = true
    const response = await sdk.Admins()
    this.admins = response.admins
    this.loading = false
  }

  async deleteAdmin (adminId: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      text: 'Once deleted, you will not be able to recover this user.',
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await sdk.DeleteAdmin({ id: adminId })
    await this.loadAdmins()
  }

  variantForRole (role: Role) {
    switch (role) {
      case Role.Admin:
        return 'success'
      case Role.Manager:
        return 'primary'
      case Role.User:
        return 'warning'
    }
  }
}
