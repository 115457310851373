







import { Component, Vue, Prop } from 'vue-property-decorator'
import editExtraGQL from '@/graphql/mutations/edit_extra.graphql'
import extraGQL from '@/graphql/queries/extra.graphql'
import Client from '@/graphql/client'
import {
  ExtraQuery,
  ExtraQueryVariables,
  Maybe,
  EditExtraMutation,
  EditExtraMutationVariables
} from '@/generated/graphql'
import ExtraForm from './Form.vue'
@Component({ components: { ExtraForm } })
export default class EditExtra extends Vue {
  @Prop() id!: string;
  saving = false;
  extra: Maybe<ExtraQuery['extra']> = null;

  async mounted () {
    this.extra = (
      await Client.query<ExtraQuery, ExtraQueryVariables>(extraGQL, {
        id: parseInt(this.id)
      })
    ).extra
  }

  async save (payload) {
    this.saving = true
    await Client.query<EditExtraMutation, EditExtraMutationVariables>(
      editExtraGQL,
      {
        id: parseInt(this.id),
        extra: payload
      }
    )
    this.saving = false
    this.$router.push({ name: 'extras' })
  }
}
