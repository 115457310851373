




import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as FilePond from 'filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import axios from 'axios'

@Component({ components: {} })
export default class ImageUpload extends Vue {
  @Prop({ default: [] }) images!: any[]
  @Prop({ default: false }) process!: boolean
  @Prop({ default: false }) multiple!: boolean

  pond: FilePond.FilePond | null = null
  existingFiles: FilePond.FilePondInitialFile[] = []
  paths: any[] = []

  @Watch('process')
  async onProcess (val) {
    if (val && this.pond) {
      await this.pond.processFiles()
      const files = this.pond.getFiles()
      this.$emit('save', { files, paths: this.paths })
      return { files, paths: this.paths }
    } else {
      return { files: [], paths: [] }
    }
  }

  mounted () {
    if (this.images.length) {
      this.existingFiles = this.images.map(i => ({
        source: i,
        options: { type: 'local' }
      }))
    }
    this.setupFilepond()
  }

  setupFilepond () {
    const options = {
      name: 'filepond',
      imageValidateSizeMinHeight: '200',
      acceptedFileTypes: ['image/*']
    } as any
    if (this.multiple) options.allowMultiple = true
    FilePond.registerPlugin(FilePondPluginImagePreview)
    FilePond.registerPlugin(FilePondPluginImageCrop)
    FilePond.registerPlugin(FilePondPluginImageValidateSize)
    FilePond.registerPlugin(FilePondPluginFileValidateType)
    this.pond = FilePond.create(this.$refs.upload as Element, options)

    this.pond.on('updatefiles', () => {
      this.$emit('update:count', this.pond!.getFiles().length)
    })

    FilePond.setOptions({
      server: {
        process: this.uploadImage,
        load: async (source, load) => {
          const { data } = await axios({ url: source, method: 'GET', responseType: 'blob' })
          load(data)
        }
      },
      instantUpload: false,
      files: this.existingFiles
    })
  }

  async uploadImage (fieldName, file, _metadata, load, error) {
    const url = (process.env.VUE_APP_API_URL || '').replace('graphql', 'image_upload') // TODO ENV var for base api
    const form = new FormData()
    form.append('Content-Type', file.type)
    form.append('upload', file)
    try {
      const { data } = await axios.post(url, form, { headers: { 'Content-Type': 'multipart/form-data' } })
      this.paths = [...this.paths, data]
      load(data.original)
    } catch (e) {
      console.log(e)
      error(e)
    }
  }
}
