















import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { Maybe, WaiterQuery, WaiterInput } from '@/generated/graphql'
@Component({})
export default class Form extends Vue {
  @Prop() waiter: Maybe<WaiterQuery['waiter']>;
  @Prop() saving;

  get processing () {
    return this.saving
  }

  form = {
    firstname: '',
    lastname: ''
  } as WaiterInput;

  validations () {
    return {
      form: {
        firstname: { required },
        lastname: { required }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.waiter) {
      this.form.firstname = this.waiter.firstname
      this.form.lastname = this.waiter.lastname
    }
  }

  async submit () {
    this.$v.form.$touch()
    if (this.$v.form.$anyError) return

    this.$emit('save', this.form)
  }
}
