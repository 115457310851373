
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BookedReservationsQuery, ReservationTimeSlot, WaitersQuery, Role, ReservationRefundMutationVariables } from '@/generated/graphql'
import groupBy from 'lodash/groupBy'
import { EventClickArg } from '@fullcalendar/core'
import { sdk } from '@/graphql/client'
import { format } from 'date-fns'

type ReservationEvent = EventClickArg['event'] & { extendedProps: { reservation: BookedReservationsQuery['reservations'][0] } }

@Component({ components: {} })
export default class ReservationModal extends Vue {
  @Prop() event!: ReservationEvent
  @Prop() waiters!: WaitersQuery['waiters']

  refundState = {
    amount: 0,
    cancel: true,
    pin: null
  }

  refunding = false

  @Watch('event')
  onEventChange () {
    console.log(this.event)
  }

  get waiterOptions () {
    return this.waiters.map((waiter) => {
      return {
        value: waiter.id,
        label: `${waiter.firstname} ${waiter.lastname}`
      }
    })
  }

  get reservation () {
    if (!this.event) return
    return this.event.extendedProps.reservation
  }

  get extras () {
    if (!this.reservation) return []
    return this.reservation.reservationExtras
  }

  get rooms () {
    if (!this.reservation) return []
    return groupBy(this.reservation.reservationTimeSlots, (slot: ReservationTimeSlot) => slot.room.title)
  }

  get hasNotes (): boolean {
    return !!(this.reservation && this.reservation.notes !== '')
  }

  async close () {
    if (this.reservation && this.reservation.adminNotes) {
      await sdk.setAdminNote({ id: this.reservation.id, note: this.reservation.adminNotes })
    }
    this.$emit('reload')
    this.$bvModal.hide('viewReservation')
  }

  async rebook () {
    if (!this.reservation) return
    this.$router.push({ name: 'rebook_reservation', params: { id: `${this.reservation.id}` } })
  }

  get waiter () {
    if (!this.reservation || !this.reservation.waiter) return
    const currentId = this.reservation.waiter.id
    const waiter = this.waiters.find((waiter) => waiter.id === currentId)
    if (!waiter) return
    return {
      value: waiter.id,
      label: `${waiter.firstname} ${waiter.lastname}`
    }
  }

  async setWaiter (event) {
    if (!this.reservation) return
    if (!event) {
      await sdk.setWaiter({ reservation: this.reservation.id })
      this.reservation.waiter = null
    } else {
      await sdk.setWaiter({ reservation: this.reservation.id, id: event.value })
    }
    this.$emit('reload')
  }

  get isUser () {
    return this.$currentUser.role === Role.User
  }

  async refund () {
    if (!this.reservation) return
    this.refunding = true
    const payload: ReservationRefundMutationVariables = { id: this.reservation?.id, amount: this.refundState.amount * 100, cancel: this.refundState.cancel }
    if (this.refundState.pin) payload.pin = Number(this.refundState.pin)
    const response = await sdk.reservationRefund(payload)
    this.refunding = false
    if (response.reservationRefund) {
      this.$bvModal.hide('refundReservation')
      this.$emit('reload')
    } else {
      alert('something went wrong')
    }
  }

  async showCancelModal () {
    if (!this.reservation) return
    this.refundState.amount = this.reservation.refundableAmount / 100
    this.$bvModal.show('refundReservation')
  }

  time (date: Date) {
    return format(date, 'hh:mm a')
  }
}
