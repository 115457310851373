























import { Component, Vue } from 'vue-property-decorator'
import ApexCharts from 'apexcharts'
import Client from '@/graphql/client'
import { DashboardQuery, DashboardQueryVariables, Maybe } from '@/generated/graphql'
import DashboardGQL from '@/graphql/queries/dashboard.graphql'
@Component({ components: {} })
export default class Dashboard extends Vue {
  data!: Maybe<DashboardQuery['dashboard']>

  get rooms () {
    if (!this.data) return {}
    return {
      chart: {
        type: 'donut'
      },
      series: this.data.rooms!.map((room) => room?.value),
      labels: this.data.rooms!.map((room) => room?.name)
    }
  }

  get extras () {
    if (!this.data) return {}
    return {
      chart: {
        type: 'donut'
      },
      series: this.data.extras!.map((extra) => extra?.value),
      labels: this.data.extras!.map((extra) => extra?.name)
    }
  }

  get sales () {
    const dataset = this.data!.reservations!
      .map(i => ({ name: new Date(i!.name).getTime(), value: i!.value }))
      .sort((a, b) => b.name - a.name)
    const data = dataset.map(i => i!.value)
    const labels = dataset.map(i => i!.name)
    return {
      labels,
      series: [{
        name: 'Sales',
        data
      }],
      chart: {
        height: 350,
        type: 'area'
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        labels: {
          formatter: val => `$${val}`
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      }
    }
  }

  async mounted () {
    this.data = (await Client.query<DashboardQuery, DashboardQueryVariables>(
      DashboardGQL
    )).dashboard
    this.$nextTick(() => {
      new ApexCharts(this.$refs.rooms, this.rooms).render()
      new ApexCharts(this.$refs.extras, this.extras).render()
      new ApexCharts(this.$refs.sales, this.sales).render()
    })
  }
}
