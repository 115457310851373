







import { Component, Vue } from 'vue-property-decorator'
import createGQL from '@/graphql/mutations/create_waiter.graphql'
import Client from '@/graphql/client'
import {
  CreateWaiterMutation,
  CreateWaiterMutationVariables
} from '@/generated/graphql'
import WaiterForm from './Form.vue'
@Component({ components: { WaiterForm } })
export default class CreateWaiter extends Vue {
  saving = false

  async save (payload: CreateWaiterMutationVariables['waiter']) {
    this.saving = true
    await Client.query<CreateWaiterMutation, CreateWaiterMutationVariables>(
      createGQL,
      {
        waiter: payload
      }
    )
    this.saving = false
    this.$router.push({ name: 'waiters' })
  }
}
