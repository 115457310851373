



























import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import swal from 'sweetalert2'
import {
  PromotionsQuery
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  promotions: PromotionsQuery['promotions'] = [];
  fields = ['dayOfWeek', 'reduction', 'actions'];
  loading = false;
  mounted () {
    this.loadPromotions()
  }

  async loadPromotions () {
    this.loading = true
    const response = await sdk.Promotions()
    this.promotions = response.promotions
    this.loading = false
  }

  dayOfWeekToString (day: number): string {
    switch (day) {
      case 0:
        return 'Sunday'
      case 1:
        return 'Monday'
      case 2:
        return 'Tuesday'
      case 3:
        return 'Wednesday'
      case 4:
        return 'Thursday'
      case 5:
        return 'Friday'
      case 6:
        return 'Saturday'
    }
    return 'Unknown'
  }

  async deletePromotion (id: number) {
    const confirm = await swal.fire({
      title: 'Are you sure?',
      confirmButtonText: 'Yes, delete it!',
      showCancelButton: true,
      text:
          'Once deleted, you will not be able to recover this promotion',
      icon: 'warning'
    })
    if (confirm.isDismissed) { return }
    this.loading = true
    await sdk.DeletePromotion({ promotion: id })
    await this.loadPromotions()
  }
}
