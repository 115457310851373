import { Maybe, Role } from '@/generated/graphql'
import JWT from 'jsonwebtoken'

type JWTUser = {
  role: Role
}

export default class CurrentUser {
  jwt?: string

  constructor () {
    const adminKey = window.sessionStorage.getItem('adminKey')
    if (adminKey) this.setJWT(adminKey)
  }

  setJWT (jwt: string) {
    this.jwt = jwt
  }

  get decoded (): Maybe<JWTUser> {
    return this.jwt ? JWT.decode(this.jwt) as JWTUser : null
  }

  get role (): Maybe<Role> {
    if (!this.decoded) return null
    return this.decoded.role
  }
}
