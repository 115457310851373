import 'mutationobserver-shim'
import './plugins/hooks'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/v-calendar'
import './plugins/num-input'
import Dialog from './plugins/dialog'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css'
import Vuelidate from 'vuelidate'
import { parseTimeToDate } from './lib/utils'
import { format } from 'date-fns'
import CurrentUser from './lib/current_user'

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

Vue.use(Vuelidate)
Vue.use(Dialog)
Vue.config.productionTip = false

// view helper that deals with formatting timeslots, including extended hours
Vue.filter('timeFormat', function (value) {
  if (!value) return ''
  return format(parseTimeToDate(value), 'h:mm a')
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  return formatter.format(value)
})

Vue.prototype.$currentUser = new CurrentUser()

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
