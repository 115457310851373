







import { Component, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import PromotionForm from './Form.vue'

@Component({ components: { PromotionForm } })
export default class createPromotion extends Vue {
  saving = false;
  async save (payload) {
    this.saving = true
    await sdk.CreatePromotion({ promotion: payload })
    console.log(payload)
    this.saving = false
    this.$router.push({
      name: 'promotions'
    })
  }
}
