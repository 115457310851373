


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import Reservations from './Reservations.vue'
import { ReservationShowQuery } from '@/generated/graphql'
import { format } from 'date-fns'

@Component({ components: { Reservations } })
export default class Admins extends Vue {
  @Prop() date!: string
  @Prop() id

  loading = false
  reservation: ReservationShowQuery['reservation'] = null

  get originalSlotCost () {
    if (!this.reservation) return 0

    return this.reservation.reservationTimeSlots.reduce((sum: number, slot) => sum + slot.price, 0)
  }

  mounted () {
    this.fetch()
  }

  onComplete () {
    this.$bvModal.show('completed-msg')
  }

  async fetch () {
    this.loading = true
    this.reservation = (await sdk.ReservationShow({ id: parseInt(this.id) })).reservation
    this.loading = false
  }

  get formattedDate () {
    if (!this.reservation) return
    const date = new Date(+this.reservation.date)
    return date.toUTCString().split(' ').splice(0, 4).join(' ')
  }

  get bookedDate (): string {
    const date = new Date(this.formattedDate!)
    return format(date, 'yyyy-MM-dd')
  }

  get currentDate (): string {
    return this.date || this.bookedDate || format(new Date(), 'yyyy-MM-dd')
  }
}
