import Vue from 'vue'
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { Maybe, TimeSlot, GiftcardQuery, GiftcardQueryVariables } from '@/generated/graphql'
import giftcardGQL from '@/graphql/queries/giftcard.graphql'
import Client from '@/graphql/client'
import { set } from 'date-fns'

const persist = ({ selectedTimeSlots, selectedExtras, date, giftCard }) => {
  localStorage.setItem('cart', JSON.stringify({ selectedTimeSlots, selectedExtras, date, giftCard }))
}

@Module({ dynamic: true, name: 'cart', store: store })
class CartStore extends VuexModule {
  selectedTimeSlots: Record<string, TimeSlot[]> = {}
  selectedExtras: Record<number, number> = {}
  giftCard: Maybe<string> = null
  discount = 0

  date = new Date()

  @Mutation
  setSelectedTimeSlots (val) {
    this.selectedTimeSlots = val
    persist(this)
  }

  @Mutation
  setSelectedExtras (val) {
    this.selectedExtras = val
    persist(this)
  }

  @Mutation
  setDate (val) {
    this.date = val
    persist(this)
  }

  @Mutation
  setExtraQuantity ({ id, quantity }) {
    Vue.set(this.selectedExtras, id, parseInt(quantity))
    persist(this)
  }

  @Mutation
  removeExtra (id: number) {
    Vue.delete(this.selectedExtras, id)
    persist(this)
  }

  @Mutation
  async setGiftcard (code: Maybe<string>) {
    this.discount = 0
    if (code) {
      const { giftcardBalance } = await Client.query<GiftcardQuery, GiftcardQueryVariables>(giftcardGQL, { code })
      if (giftcardBalance && giftcardBalance.left > 0) {
        this.giftCard = code
        this.discount = giftcardBalance.left
      }
    }
    persist(this)
  }

  @Mutation
  removeSlot ({ room, slot }) {
    const selected = this.selectedTimeSlots[room].filter(i => i.id !== slot.id)
    if (selected.length === 0) {
      // remove roomId key as well if not timeslots selected anymore
      Vue.delete(this.selectedTimeSlots, room)
      this.selectedExtras = {}
    } else {
      this.selectedTimeSlots = { ...this.selectedTimeSlots, ...{ [room]: selected } }
    }
    persist(this)
  }

  @Action
  async load () {
    const cart = localStorage.getItem('cart')
    if (!cart) return

    const { selectedTimeSlots, selectedExtras, date, giftCard } = JSON.parse(cart as string)
    const midnight = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }
    const cartDate = set(new Date(date), midnight)
    const currentDate = set(new Date(), midnight)
    const notExpired = cartDate >= currentDate

    if (notExpired) {
      this.setGiftcard(giftCard)
      this.setSelectedTimeSlots(selectedTimeSlots)
      this.setSelectedExtras(selectedExtras)
      this.setDate(cartDate)
    }
  }

  @Action
  async clear () {
    this.setSelectedTimeSlots({})
    this.setSelectedExtras({})
    this.setDate(new Date())
    this.setGiftcard('')
    await localStorage.removeItem('cart')
  }
}

export default getModule(CartStore, store)
