






































import { Component, Vue, Watch } from 'vue-property-decorator'
import giftcardsGQL from '@/graphql/queries/giftcards.graphql'
import giftcardResendGQL from '@/graphql/mutations/giftcard_resend.graphql'
import Client from '@/graphql/client'
import swal from 'sweetalert2'
import {
  GiftcardsQuery,
  GiftcardsQueryVariables,
  GiftcardResendMutation,
  GiftcardResendMutationVariables
} from '@/generated/graphql'
@Component({ components: {} })
export default class Rooms extends Vue {
  extras: GiftcardsQuery['giftcards'] = [];
  fields = ['senderName', 'recipient', 'amount', 'left', 'actions'];

  filters = {
    code: '',
    senderName: '',
    recipient: ''
  }

  loading = false;
  mounted () {
    this.loadGiftcards()
  }

  async loadGiftcards () {
    this.loading = true
    const response = await Client.query<GiftcardsQuery, GiftcardsQueryVariables>(
      giftcardsGQL, { ...this.filters }
    )
    this.extras = response.giftcards
    this.loading = false
  }

  async resend (id) {
    const response = await Client.query<GiftcardResendMutation, GiftcardResendMutationVariables>(giftcardResendGQL, { id })
    if (!response) return
    swal.fire(
      'Gift card Sent!',
      `The gift card was sent successfully to ${response.giftcardResend!.recipient}`,
      'success'
    )
  }

  @Watch('filters', { deep: true })
  onFilterChange () {
    this.loadGiftcards()
  }
}
