
























import { Component, Vue, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { Maybe, ExtraInput, ExtraQuery } from '@/generated/graphql'
import ImageUpload from '@/components/ImageUpload.vue'
import { FilePondFile } from 'filepond'
@Component({ components: { ImageUpload } })
export default class Form extends Vue {
  imageCount = 0
  process = false
  images: string[] = []

  @Prop() extra: Maybe<ExtraQuery['extra']>;
  @Prop() saving;

  form = {
    title: '',
    description: '',
    price: 50,
    value: null,
    includeInGratuity: true,
    image: { path: '' }
  } as ExtraInput;

  validations () {
    return {
      form: {
        title: { required },
        description: { required },
        price: { required },
        image: {
          path: { required }
        }
      }
    }
  }

  validateState (name) {
    const $dirty = this.$v.form[name]?.$dirty
    const $error = this.$v.form[name]?.$error
    return $dirty ? !$error : null
  }

  created () {
    if (this.extra) {
      this.images = [this.extra.image!.path]
      this.form.title = this.extra.title
      this.form.description = this.extra.description || ''
      this.form.price = this.extra.price
      this.form.value = this.extra.value
      this.form.includeInGratuity = this.extra.includeInGratuity
    }
  }

  async save () {
    this.$v.form.$touch()

    const images = await (this.$refs.upload as ImageUpload).onProcess('doit')
    const path = images.files.length ? this.path(images.files[0]) : ''
    const variants = images.paths.find(i => i.original === path)
    this.form.image = { path, variants }

    if (this.$v.form.$anyError) {
      this.saving = false
      return
    }
    this.$emit('save', this.form)
    this.saving = false
  }

  path (file: FilePondFile) {
    return file.serverId.startsWith('http')
      ? file.serverId
      : `https://lalarooms.s3.amazonaws.com/${file.serverId}`
  }
}
