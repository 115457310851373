







import { Component, Vue, Prop } from 'vue-property-decorator'
import { sdk } from '@/graphql/client'
import {
  AdminCreateInput,
  AdminQuery,
  Maybe
} from '@/generated/graphql'
import AdminForm from './Form.vue'
@Component({ components: { AdminForm } })
export default class EditAdmin extends Vue {
  @Prop() id!: string;
  saving = false;
  admin: Maybe<AdminQuery['admin']> = null;

  async mounted () {
    this.admin = (await sdk.Admin({ id: Number(this.id) })).admin
  }

  async save (payload: AdminCreateInput) {
    this.saving = true
    await sdk.UpdateAdmin({ id: Number(this.id), admin: payload })
    this.saving = false
    this.$router.push({ name: 'admins' })
  }
}
