
































import { Component, Vue } from 'vue-property-decorator'
import LoginGQL from '@/graphql/mutations/login.graphql'
import Client from '@/graphql/client'
import { LoginMutation, LoginMutationVariables } from '@/generated/graphql'
@Component({ components: {} })
export default class Login extends Vue {
  submitting = false;
  form = {
    email: '',
    password: ''
  };

  loginFailed = false

  async submit () {
    this.submitting = true
    this.loginFailed = false
    try {
      const response = await Client.query<
        LoginMutation,
        LoginMutationVariables
      >(LoginGQL, this.form)
      if (response.login) {
        window.sessionStorage.setItem('adminKey', response.login)
        Client.setKey(response.login)
        this.$currentUser.setJWT(response.login)
        this.$router.push({ name: 'dashboard' })
      }
    } catch (error) {
      this.loginFailed = true
      console.log(error)
    }
    this.submitting = false
  }
}
